<!--TODO: eslint -->
<template>
  <div class="support-page">
    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Профиль</div></a
    >

    <ul class="feedback-nav">
      <li>
        <img src="../assets/myorders-icon.svg" alt="" />
        <router-link to="/tariff">Тариф</router-link>
      </li>

      <li>
        <img src="../assets/support-call.svg" alt="" />
        <router-link to="/profilesett">Личные данные</router-link>
      </li>
      <li>
        <img src="../assets/support-chat.svg" alt="" />
        <router-link to="/supportnav2">Поддержка</router-link>
      </li>
      <li>
        <img src="../assets/support-call.svg" alt="" />
        <router-link to="/choosenotis"> Настройка уведомлений</router-link>
      </li>
      <li>
        <img src="../assets/support-call.svg" alt="" />
        <router-link to="/about">О приложении</router-link>
      </li>
      <li>
        <img src="../assets/support-call.svg" alt="" />
        <router-link to="/confid">Конфиденциальность</router-link>
      </li>
      <li>
        <img src="../assets/support-call.svg" alt="" />
        <router-link to="/rules">Правила участия</router-link>
      </li>
    </ul>

    <routerLink to="/"
      ><button
        style="
          margin: 0 auto;
          font-size: 14px;
          line-height: 17px;
          background: rgba(57, 178, 186, 0.2);
          mix-blend-mode: normal;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 7px;
          color: #39b2ba;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          padding: 3% 20%;
          margin-top: 10%;
        "
      >
        Выйти
      </button></routerLink
    >

    <p
      style="
        margin: 0 auto;
        color: #818181;
        font-size: 8px;
        line-height: 10px;
        margin-top: 5%;
      "
    >
      Текущая версия: 1.0
    </p>

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "ProfileComponent",
  data: () => ({
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
  }),
  methods: {
    back() {
      this.$emit("back", false);
    },
  },
};
</script>
<style scoped lang="scss">
ul.menu {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  margin: 0;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}
ul.menu li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}
.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}
.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}
.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}
.header-icon {
  margin-right: 10px;
}
.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}
header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));
  top: 0;
}
.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}
.user,
.controls {
  width: min-content;
  margin: 0;
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
ul {
  text-align: left;
  margin: 15px;
  list-style: none;
}
ul li {
  color: black;
  display: flex;
  padding: 17px 0;
  align-items: center;
  border-bottom: 1px solid #dadada;
}
a {
  color: black;
}
ul li:first-child {
  padding-top: 0;
}
ul li img {
  padding: 0 10px;
}
</style>
