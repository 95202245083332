export const getMonthName = (month) =>  {
    const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
        "июля", "августа", "сентября", "октября", "ноября", "декабря"]
    return monthNames[month]
}

export const getStringDate = (timeStamp) =>  {
    return `${new Date(timeStamp*1000).getDate()} ${getMonthName(new Date(timeStamp*1000).getMonth())} ${new Date(timeStamp*1000).getFullYear()}`
}

export const getStringDateForDate = (timeStamp) =>  {
    return `${new Date(timeStamp).getDate()} ${getMonthName(new Date(timeStamp).getMonth())} ${new Date(timeStamp).getFullYear()}`
}