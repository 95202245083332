<!--TODO: eslint -->
<template>
  <div class="question-page">

    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Обратная связь</div></a
    >

    <form>
      <label for="">Тема обращения<input type="text" /></label>
      <label for=""
        >Ваш вопрос <textarea name="" id="" cols="30" rows="20"></textarea>
      </label>

      <input
        class="file-input"
        style="box-shadow: none; width: 300px"
        type="file"
      />
      <button>Отправить сообщение</button>
    </form>

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "HelloWorld",

  methods: {
    back() {
      this.$emit("back", false);
    },
    openCalend() {
      this.$emit("openCalend", false);
    },
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}
ul li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}
.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}
* {
  margin: 0;
  padding: 0;
}
label {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: 700;
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;

  color: #2da9a1;
}
input,
textarea {
  margin: 7px 0;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 27px;
}
.file-input {
  position: relative;
  padding-left: 20px;
}
.file-input::before {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  content: "";
  background-image: url("../assets/addfile.svg");
}
textarea {
  height: 100%;
  resize: none;
}
form {
  padding: 16px;
}

.user {
  display: flex;
  align-items: flex-end;
}
.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}
.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}
.header-icon {
  margin-right: 10px;
}
.question-page {
  margin-top: 12%;
  background: white;

  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}
header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}
.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}
.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
button {
  background: rgba(57, 178, 186, 0.2);
  padding: 12px;
  margin-top: 5%;
  width: 80%;
  color: #39b2ba;
  font-size: 15px;
  border-radius: 7px;
}
</style>
