<!--TODO: eslint -->
<template>
  <div class="support-page">
    <a href="#" onClick="javascript:history.go(-1); return false">
      <div class="back" @click="back()">Коран</div>
    </a>
    <!-- <div style="position: relative" class="searchbar">
      <input placeholder="Поиск" type="text" />
      <img
        style="position: absolute; top: 30%; right: 20%"
        src="../assets/loopa.svg"
        alt=""
      /> -->
    <!-- <img @click="showSelect=true" style="margin-left: 10px" src="../assets/koranSettings.svg" alt=""> -->
    <!-- </div> -->
    <!-- <div style="display: flex; padding: 5% 10%; justify-content: center;">
   <p>АЛЬ-ФАТИХА</p>
   <span>(ОТКРЫВАЮЩАЯ)</span>
 </div> -->
    <!-- <div v-if="!surah" class="loading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div> -->
    <ul>
      <img
        style="width: 300px; margin: 0 auto"
        v-if="
          $store.getters.getSurahSelected != 1 &&
          $store.getters.getSurahSelected != 9
        "
        src="../assets/surahbefore.png"
        alt=""
      />
      <li v-for="(s, index) in surah" :key="s.text">
        <div class="numberCircle">{{ s.stih }}</div>
        <div @click="callStore(s.surah, s.stih)">
          <div style="width: 250px; text-align: right">
            <h1 dir="rtl" translate="no">{{ arabicSurah[index].text }}</h1>
          </div>

          <span style="font-size: 15px; line-height: 20px; color: black">{{
            shorter(s.text)
          }}</span>
        </div>

        <img
          @click="like(s)"
          v-if="!clicked.includes(s)"
          src="../assets/simple-like.svg"
        />
        <img v-else src="../assets/like.svg" />
      </li>
      <!-- <li><div> <h1>مِيِحَرلرا نِمَحْلرَّا هِللّا مِسْبِ</h1> <span style="font-size: 15px;
line-height: 15px;color: black;">С именем Аллаха Милостивого, Милосердного!</span> </div> <img src="../assets/Number.svg" alt=""></li>
<li><div> <h1>مِيِحَرلرا نِمَحْلرَّا هِللّا مِسْبِ</h1> <span style="font-size: 15px;
line-height: 15px;color: black;">С именем Аллаха Милостивого, Милосердного!</span> </div> <img src="../assets/Number.svg" alt=""></li> -->
    </ul>

    <div
      style="z-index: 1000; background-color: #fff"
      v-if="showSelect"
      class="select"
    >
      <img
        @click="showSelect = false"
        src="../assets/X.svg"
        width="10"
        style="position: absolute; right: 20px"
        alt=""
      />
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <button>Сохранить</button>
    </div>

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-active.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
// import VueToggleImage from "@ivahid/vue-toggle-image";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import store from "../store/index.js";
// import "@ivahid/vue-toggle-image/dist/vue-toggle-image.css";
import axios from "axios";
export default {
  // name: "HelloWorld",
  data: () => ({
    fav: [],
    clicked: [],
    surah: "",
    arabicSurah: [],
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
    showSelect: false,
  }),
  // components: { VueToggleImage },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async like(s) {
      this.clicked.push(s);
      let data3 = `surah_id=${
        Number(localStorage.getItem("selected")) + 1
      }&ayah_id=${s.stih}&folder_id=0&fav=1`;
      await axios
        .post(
          "https://api.sabil.webinfra.ru/sabil",
          `{"path": "https://api.sabeel.credo.ru:5543/api/fav_ayah", "data": ${JSON.stringify(
            data3
          )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID": ${localStorage.getItem(
            "sessid"
          )}}}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
    },
    callStore(s, st) {
      store.commit("SET_AYAT", {
        surah: s,
        stih: st,
      });
      localStorage.setItem("ayat", st);
      this.$router.push("/ayat");
    },
    shorter(s) {
      if (s.length > 24) {
        let str = s.slice(0, 25);
        return `${str}...`;
      } else {
        return s;
      }
    },
    arabicShorter(arabic) {
      if (arabic.split(";").length > 5000) {
        let str = arabic.split(";").slice(50, 100).join(";");
        return "..." + str;
      } else {
        return arabic;
      }
    },

    back() {
      this.$emit("back", false);
    },
  },
  async created() {
    this.fav = JSON.parse(localStorage.getItem("fav")).fav_list;
    let that = this;

    const selected = localStorage.getItem("selected");
    this.$store.commit("SET_ARRAYLIST");
    this.$store.commit("SET_NEW");

    that.surah = JSON.parse(
      JSON.stringify(this.$store.getters.getNewArray[selected].data.quran)
    );
    that.arabicSurah = that.surah.filter((s) => {
      return s.translation == 3 && s.stih != 0;
    });
    that.surah = that.surah.filter((s) => {
      return s.translation == 2 && s.stih != 0;
    });

    // await axios
    //   .post(
    //     "https://api.sabil.webinfra.ru/sabil",
    //     `{"path": "https://api.sabeel.credo.ru:5543/api/quran", "data": ${JSON.stringify(
    //       data3
    //     )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID": "${
    //       this.$store.getters.getPin
    //     }"}}`,
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   )
    //   .then(function (response) {
    //     // cookieJar.myCookies = response.headers['Content-Type'];
    //     // console.log(cookieJar.myCookies)
    //     console.log(response.data);
    //     that.surah = JSON.parse(JSON.stringify(response.data.quran));
    //     that.arabicSurah = that.surah.filter((s) => {
    //       return s.translation == 3 && s.stih != 0;
    //     });
    //     that.surah = that.surah.filter((s) => {
    //       return s.translation == 2 && s.stih != 0;
    //     });

    //     console.log(JSON.stringify(response.data));
    //     console.log("v1", that.surah);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     return false;
    //   });
  },
};
</script>
<style scoped lang="scss">
@font-face {
  font-family: "koranFont";
  src: url("../fonts/ScheherazadeNew-Regular.ttf");
}

h1 {
  font-family: "koranFont" !important;
}

.numberCircle {
  border-radius: 50%;
  width: 35px !important;
  line-height: 35px;
  text-align: center;
  height: 35px !important;
  padding: 0px;

  background: #fff;
  border: 2px solid #2da9a1;
  color: #2da9a1;
  text-align: center;

  font-size: 20px;
}

ul {
  list-style: none;
  padding: 5%;
  padding-bottom: 60px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}

ul li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3% !important;
  // width: 200px
}

img {
  justify-self: flex-end;
}

button {
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  width: 80%;
  padding: 10px;
  margin: 0 auto;
  color: #39b2ba;
}

.select {
  margin: 10%;
  margin-top: 5%;
  position: absolute;
  top: -30%;
  padding: 30px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  position: relative;
  height: min-content;
}

select {
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-transform: capitalize;
  color: #000000;
  width: 100%;
}

.select-item {
  display: inline-flex;
  margin: 5% auto;
}

label {
  text-align: left;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: uppercase;

  color: #2da9a1;
}

ul {
  padding: 10px;
  padding-bottom: 60px;
}

ul li {
  display: flex;

  padding: 2% 10%;
  border-bottom: 1px solid #dadada;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
}

ul li p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #000000;
}

.searchbar input {
  background: #ffffff;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 70%;
  padding: 3px;
  font-weight: 400;
  font-size: 12px;
  padding: 3%;
  line-height: 15px;
  color: #a8a8a8;
  // position: relative;
}

.searchbar {
  position: relative;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}

.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.select {
  z-index: 1000;
}

.user {
  display: flex;
  align-items: flex-end;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

.header-icon {
  margin-right: 10px;
}

.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}

header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}

.footer-icon {
  padding: 10px;
}

ul li div h1 {
  font-size: 20px !important;
  width: 250px;
  // letter-spacing: 5px;
  margin-right: 0px;
  // padding-bottom: 15px;
  color: #2da9a1;
  line-height: 35px !important;
  display: inline;
}

ul li div {
  text-align: left;
}

li div h1 {
  font-size: 25px !important;

  display: inline;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2da9a1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
</style>
