<!--TODO: eslint -->
<template>
  <div class="support-page">


    <a href="#" onClick="javascript:history.go(-1); return false">
      <div class="back" @click="back()">Мой тариф</div>
    </a>

    <h1>Мой тариф</h1>
    <div>
      <div style="display: flex; justify-content: space-between; margin: 10px 4%">
        <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #000000;
        ">
          Абонентская плата
        </p>
        <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
        ">
          {{ t.COST }} руб за 30 дней
        </p>
      </div>

      <div style="display: flex; justify-content: space-between; margin: 0 4%">
        <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #000000;
        ">
          Дата следующего списания
        </p>
        <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
        ">
          20.07.2022
        </p>
      </div>

      <div class="tariff">
        <div>
          <img src="../assets/trubka.svg" alt="" />
          <p>{{ t.NAME.split('-')[0] }} минут</p>
        </div>
        <div>
          <img src="../assets/trubka.svg" alt="" />
          <p>{{ t.NAME.split('-')[2] }}Гб</p>
        </div>
        <div>
          <img src="../assets/trubka.svg" alt="" />
          <p>{{ t.NAME.split('-')[1] }} СМС</p>
        </div>
      </div>
    </div>


    <!-- <div style="display: flex; gap: 5px; padding: 5%">
      <img src="../assets/call.svg" alt="" />
      <h3 style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;

          color: #000000;
        ">
        Звонки
      </h3>
    </div>

    <div style="
        display: flex;
        justify-content: space-between;
        margin: 3%;
        border-bottom: 0.5px solid #afafaf;
        padding: 2%;
      ">
      <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 7px;
          text-align: left;
          line-height: 12px;
          color: #000000;
        ">
        Звонки на все мобильные номера региона пребывания
      </p>
      <span style="
          font-family: 'Montserrat';
          font-style: normal;
          text-align: left;
          font-weight: 700;
          margin-top: 3%;
          white-space: nowrap;
          font-size: 14px;
          line-height: 12px;
        ">50 минут</span>
    </div>

    <h2 style="
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #2da9a1;
        text-transform: uppercase;
        text-align: left;
        padding: 5% 10%;
      ">
      Купленные Услуги
    </h2>

    <div class="switch" style="
        display: flex;
        gap: 2%;
        margin: 0% 10%;
        border-bottom: 1px solid #dadada;
        padding: 2%;
      ">
      <img src="../assets/Switch.svg" alt="" />
      <p style="font-size: 12px; line-height: 15px">
        Роуминг <b style="font-size: 12px; line-height: 15px"> 150 ₽/мес</b>
      </p>
    </div>

    <div class="switch" style="
        display: flex;
        gap: 2%;
        margin: 0% 10%;
        border-bottom: 1px solid #dadada;
        padding: 2%;
      ">
      <img src="../assets/Switch.svg" alt="" />
      <p style="font-size: 12px; line-height: 15px">
        Роуминг <b style="font-size: 12px; line-height: 15px"> 150 ₽/мес</b>
      </p>
    </div> -->
    <router-link to="/changetariff"><button style="
          color: #39b2ba;
          font-size: 14px;
          line-height: 16px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 7px;
          background: rgba(57, 178, 186, 0.2);
          padding: 3% 15%;
          margin-bottom: 100px;
          margin: 3%;
        ">
        Сменить тариф
      </button>
    </router-link>

    <footer style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      ">
      <div class="left">
        <router-link to="/koran"><img src="../assets/koran-black.svg" alt="" class="footer-koran footer-icon" />
        </router-link>
        <router-link to="/imam"><img src="../assets/imam-footer.svg" alt="" class="footer-question footer-icon" />
        </router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt="" />
      </router-link>
      <div class="right">
        <router-link to="/calend"><img src="../assets/Icon3.svg" alt="" class="footer-calend footer-icon"
            @click="openCalend()" /></router-link>
        <router-link to="/blago"><img src="../assets/Icon4.svg" alt="" class="footer-blagos footer-icon" />
        </router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import axios from "axios";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "HelloWorld",
  data: () => ({
    t: {ID_SERVICE: 2241, COST: 279, NAME: "300-300-15"},
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
  }),
  methods: {
    back() {
      this.$emit("back", false);
    },
  },
  async created(){
    let that = this;
    this.t =  JSON.parse(localStorage.getItem('tariff'))[1]
    await axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/tariff_list", "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":  ${localStorage.getItem("sessid")}}}`
      )
      .then(function (response) {
        that.tariffs = response.data.tariffs;
      })
      .catch(function (error) {
        console.error(error);
        return false;
      });

  }
};
</script>
<style scoped lang="scss">
p,
h3,
b {
  font-size: 14px !important;
  line-height: 20px !important;
}

ul.menu {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin: 0;
  color: black;
  text-align: left;
}

ul.menu li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}

.tariff {
  margin: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #000000;

  border-bottom: 0.5px solid #afafaf;
}

.tariff div {
  padding: 10px 0;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  display: block;
  text-align: left;
  padding-bottom: 20px;
  margin: 20px;
  border-bottom: 0.5px solid #afafaf;
  color: #39b2ba;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  padding: 10px 40px;
  text-align: left;
  position: relative;
}

.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

.header-icon {
  margin-right: 10px;
}

.support-page {
  margin-top: 12%;
  padding-bottom: 100px;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100%;
  background-repeat: repeat;
  position: relative;
}

header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}

.footer-icon {
  padding: 10px;
}

ul {
  text-align: left;
  margin: 15px;
  list-style: none;
}

ul li {
  display: flex;
  padding: 17px 0;
  align-items: center;
  border-bottom: 1px solid #dadada;
}

ul li:first-child {
  padding-top: 0;
}

ul li img {
  padding: 0 10px;
}
</style>
