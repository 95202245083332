<!--TODO: eslint -->
<template>
  <div class="support-page">
    <div v-if="showNots" style="
        position: fixed;
        margin-top: 20%;
        z-index: 1000;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        top: 0;
        text-align: left;
        right: 0;
        bottom: 5%;
        height: min-content;
        width: 80%;
        background-color: white;
        padding: 8% 5%;
      ">
      <img @click="showNots = false" style="position: absolute; right: 5%" src="../assets/X.svg" alt="" />

      <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;

          color: #000000;
          margin-bottom: 5%;
        ">
        Последние непрочитанные уведомления
      </p>
      <div style="
          display: flex;
          border-bottom: 1px solid #dadada;
          padding: 5% 0;
          gap: 1%;
          z-index: 1000;
        ">
        <div>
          <p style="
              color: #2da9a1;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
            ">
            Вопрос имаму
          </p>
          <p style="
              font-weight: 400;
              font-size: 11px;
              line-height: 13px;

              color: #000000;
            ">
            Получен ответ на вопрос имаму
          </p>
        </div>

        <p style="
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            color: #363636;
          ">
          15:30 - 02.03.2022
        </p>
      </div>
      <div style="
          display: flex;
          border-bottom: 1px solid #dadada;
          padding: 5% 0;
          gap: 1%;
          z-index: 1000;
        ">
        <div>
          <p style="
              color: #2da9a1;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
            ">
            Вопрос имаму
          </p>
          <p style="
              font-weight: 400;
              font-size: 11px;
              line-height: 13px;

              color: #000000;
            ">
            Получен ответ на вопрос имаму
          </p>
        </div>

        <p style="
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            color: #363636;
          ">
          15:30 - 02.03.2022
        </p>
      </div>
      <router-link to="/nots">
        <p style="
            color: #39b2ba;
            text-align: right;
            font-size: 9px;
            line-height: 11px;
            margin-top: 3%;
          ">
          Посмотреть все уведомления
        </p>
      </router-link>
    </div>
    <div @click="showMenu = false" v-if="showModal || showMenu || showNots || showShare" style="
        z-index: 999;
        position: absolute;
        display: inline;
        top: -20%;
        left: 0;
        width: 100%;
        height: 200fvh;
        opacity: 0.3;
        background-color: black;
        overflow-y: hidden;
      "></div>
    <ul class="menu" v-if="showMenu" style="
        z-index: 10000;
        position: fixed;
        width: 70%;
        right: 0;
        bottom: 0;
        top: 0;
        background: white;
      ">
      <li>
        <router-link to="/news">Новости</router-link>
      </li>
      <li>
        <router-link to="/tranz">Транзакции</router-link>
      </li>
      <li>
        <router-link to="/myconn">Моя связь</router-link>
      </li>

      <li>
        <router-link to="/supportnav">Техподдержка</router-link>
      </li>
    </ul>

    <div @click="showModal = false" v-if="showModal" style="
        z-index: 800;
        position: absolute;
        display: inline;
        top: -20vh;
        left: 0;
        width: 100%;
        height: 200vh;
        opacity: 0.3;
        background-color: black;
        overflow-y: hidden;
      "></div>

    <div v-if="showModal" class="subModal" style="
        position: absolute;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 15%;
        left: 10%;
        bottom: 5%;
        height: min-content;
        width: 80%;
        text-align: center;
        background-color: white;
        z-index: 1000;
        padding: 8%;
      ">
      <img @click="showModal = false" style="position: absolute; right: 5%; top: 5%" src="../assets/X.svg" alt="" />
      <img src="../assets/approve.svg" alt="" />
      <h1>Успешно</h1>
      <p>Тариф успешно изменен</p>

      <button @click="$router.push('/tariff')">
        Вернуться
      </button>
    </div>
    <a href="#" onClick="history.go(-1)">
      <div class="back" @click="back()">Мой тариф</div>
    </a>

    <h1>Настройте свой тариф</h1>
    <p style="
        font-size: 9px;
        line-height: 11px;
        border-bottom: 0.5px solid #afafaf;
        padding-bottom: 2%;
        text-align: left;
        margin: 5%;
      ">
      Выберите необходимые пакеты минут, Гб и СМС
    </p>

    <div :class="changeSelected == t.ID_SERVICE ? 'filterStyle' : null" @click="changeSelected = t.ID_SERVICE"
      v-for="t in tariffs" :key="t.ID_SERVICE">
      <div style="display: flex; justify-content: space-between; margin: 10px 4%">
        <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #000000;
        ">
          Абонентская плата
        </p>
        <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
        ">
          {{ t.COST }} руб за 30 дней
        </p>
      </div>

      <div style="display: flex; justify-content: space-between; margin: 0 4%">
        <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #000000;
        ">
          Дата следующего списания
        </p>
        <p style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
        ">
          20.07.2022
        </p>
      </div>

      <div class="tariff">
        <div>
          <img src="../assets/trubka.svg" alt="" />
          <p>{{ t.NAME.split('-')[0] }} минут</p>
        </div>
        <div>
          <img src="../assets/trubka.svg" alt="" />
          <p>{{ t.NAME.split('-')[2] }}Гб</p>
        </div>
        <div>
          <img src="../assets/trubka.svg" alt="" />
          <p>{{ t.NAME.split('-')[1] }} СМС</p>
        </div>
      </div>
    </div>




    <button v-if="changeSelected" @click="callChange(); showModal = true" style="
          color: #39b2ba;
          font-size: 14px;
          line-height: 16px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 7px;
          background: rgba(57, 178, 186, 0.2);
          padding: 3% 15%;
          margin: 3%;
        ">
      Сменить тариф
    </button>

    <footer style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      ">
      <div class="left">
        <router-link to="/koran"><img src="../assets/koran-black.svg" alt="" class="footer-koran footer-icon" />
        </router-link>
        <router-link to="/imam"><img src="../assets/imam-footer.svg" alt="" class="footer-question footer-icon" />
        </router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt="" />
      </router-link>
      <div class="right">
        <router-link to="/calend"><img src="../assets/Icon3.svg" alt="" class="footer-calend footer-icon"
            @click="openCalend()" /></router-link>
        <router-link to="/blago"><img src="../assets/Icon4.svg" alt="" class="footer-blagos footer-icon" />
        </router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import axios from "axios";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
// import HeaderComponent from "@/components/HeaderComponent.vue";
export default {
  name: "HelloWorld",
  // components: {HeaderComponent},
  data: () => ({
    changeSelected: null,
    tariffs: [],
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
    userIconPath:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png",
  }),
  methods: {
    getUserIconPath() {
      return this.userIconPath;
    },
    back() {
      this.$emit("back", false);
    },
    callChange() {
      let that = this;
      let data2 = `tariff_id=${this.changeSelected}`;
      axios
        .post(
          "https://api.sabil.webinfra.ru/sabil",
          `{"path": "https://api.sabeel.credo.ru:5543/api/tariff_change", "data":${JSON.stringify(
            data2
          )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":  ${localStorage.getItem("sessid")}}}`
        )
        .then(function (response) {
          localStorage.setItem('tariff', JSON.stringify(response.data.services))
          that.tariffs = response.data.tariffs;
        })
        .catch(function (error) {
          console.error(error);
          return false;
        });

    }
  },
  async created() {
    let that = this;

    await axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/tariff_list", "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":  ${localStorage.getItem("sessid")}}}`
      )
      .then(function (response) {
        that.tariffs = response.data.tariffs;
      })
      .catch(function (error) {
        console.error(error);
        return false;
      });

  }
};
</script>
<style scoped lang="scss">
.filterStyle {
  background-color: #39b1ba34;
  padding: 5%;
}

.subModal p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #636363;
  margin-top: 8px;
}

.subModal div {
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  padding-bottom: 5px;
}

.subModal button {
  color: #39b2ba;
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  height: 40px;
  margin: 5% auto;
  margin-bottom: 0;
}

.tariff {
  margin: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #000000;

  border-bottom: 0.5px solid #afafaf;
}

.tariff div {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: block;
  text-align: center;
  margin: 2%;
  color: #39b2ba;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  padding: 10px 40px;
  text-align: left;
  position: relative;
}

.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

.header-icon {
  margin-right: 10px;
}

.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 150vh;
  background-repeat: repeat;
  position: relative;
}

header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}

.footer-icon {
  padding: 10px;
}

ul {
  text-align: left;
  margin: 4%;
  margin-top: 2%;
  list-style: none;
}

ul li {
  color: #39b2ba;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

input {
  width: 3%;
}

ul.menu {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black !important;
  text-align: left;
}

ul.menu li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
  color: black !important;
}
</style>
