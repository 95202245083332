<!--TODO: eslint -->
<template>
  <div class="root">
    <div class="header-container">
      <p class="date">{{getStringDate(newsInfo.date)}}</p>
<!--      <div class="like-btn"></div>-->
<!--      <div class="like-btn__empty"></div>-->
    </div>
    <h1>{{ newsInfo.title }}</h1>
    <div class="sub" style="display: flex; justify-content: space-between">
      <p>{{ newsInfo.featured_image.region }}</p>
    </div>
    <img :src="newsInfo.featured_image.url" alt="" />
    <p v-html="newsInfo.html"
     class="main-page__text"></p>
  </div>
</template>
<script>
import store from "../store/index.js";
import {getStringDate} from "@/utils/helpers";
import {getArticle} from "@/api/api";
export default {
  methods: {getStringDate},
  data: () => ({
    newsInfo: null,
  }),
  async created() {
    let that = this;

    const data3 = "page_id=" + store.getters.getPostSelected;
    await getArticle(data3, this.$store.getters.getPin)
        .then(function (response) {
          that.newsInfo = response.data;
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
  }
}
</script>
<style scoped>
.root {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30%;
  padding: 5%;
}
.date {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;

  color: #363636;
}
h1 {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #2da9a1;
}
.sub p {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;

  color: #363636;
}
img {
  max-width: 100%;
  /*height: 200px;*/
  object-fit: cover;
}
.main-page__text {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.header-container {
  display: flex;
  justify-content: space-between;
}

.like-btn {
  width: 15px;
  height: 15px;
  /*position: absolute;*/
  /*right: 5%;*/
  /*bottom: 5%;*/
  background-image: url("../assets/like.svg");
}

.like-btn__empty {
  width: 15px;
  height: 15px;
  /*position: absolute;*/
  /*right: 5%;*/
  /*bottom: 5%;*/
  background-image: url("../assets/like-project.svg");
}
</style>
