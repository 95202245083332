var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suport-page"},[(_vm.showMenu)?_c('ul',{staticStyle:{"z-index":"1000","position":"fixed","width":"70%","right":"0","bottom":"0","top":"0","background":"white"}},[_c('li',[_c('router-link',{attrs:{"to":""}},[_vm._v("Избранное")])],1),_c('li',[_c('router-link',{attrs:{"to":""}},[_vm._v("Актуальное")])],1),_c('li',[_c('router-link',{attrs:{"to":""}},[_vm._v("События ")])],1),_c('li',[_vm._v("Мультимедиа")])]):_vm._e(),(_vm.showNots)?_c('div',{staticStyle:{"position":"fixed","margin-top":"20%","z-index":"1000","box-shadow":"4px 4px 4px rgba(0, 0, 0, 0.25)","top":"0","text-align":"left","right":"0","bottom":"5%","height":"min-content","width":"80%","background-color":"white","padding":"8% 5%"}},[_c('img',{staticStyle:{"position":"absolute","right":"5%"},attrs:{"src":require("../assets/X.svg"),"alt":""},on:{"click":function($event){_vm.showNots = false}}}),_c('p',{staticStyle:{"font-family":"'Montserrat'","font-style":"normal","font-weight":"400","font-size":"11px","line-height":"13px","color":"#000000","margin-bottom":"5%"}},[_vm._v(" Последние непрочитанные уведомления ")]),_vm._m(0),_vm._m(1),_c('router-link',{attrs:{"to":"/nots"}},[_c('p',{staticStyle:{"color":"#39b2ba","text-align":"right","font-size":"9px","line-height":"11px","margin-top":"3%"}},[_vm._v(" Посмотреть все уведомления ")])])],1):_vm._e(),(_vm.showModal || _vm.showMenu || _vm.showNots || _vm.showShare || _vm.showMenu2)?_c('div',{staticStyle:{"z-index":"999","position":"absolute","display":"inline","top":"-20%","left":"0","width":"100%","height":"120vh","opacity":"0.3","background-color":"black","overflow-y":"hidden"},on:{"click":function($event){_vm.showMenu = false;
      _vm.showMenu2 = false;}}}):_vm._e(),(_vm.showMenu2)?_c('ul',{staticStyle:{"z-index":"10000","position":"fixed","width":"70%","right":"0","bottom":"0","top":"0","background":"white"}},[_c('li',[_c('router-link',{attrs:{"to":"/news"}},[_vm._v("Новости")])],1),_c('li',[_c('router-link',{attrs:{"to":"/tranz"}},[_vm._v("Транзакции")])],1),_c('li',[_c('router-link',{attrs:{"to":"/myconn"}},[_vm._v("Моя связь")])],1),_c('li',[_c('router-link',{attrs:{"to":"/supportnav"}},[_vm._v("Техподдержка")])],1)]):_vm._e(),_c('a',{attrs:{"href":"#","onClick":"javascript:history.go(-1); return false"}},[_c('div',{staticClass:"back",on:{"click":function($event){return _vm.back()}}},[_vm._v("Новости")])]),_c('tabs',{attrs:{"mode":_vm.mode}},[_c('tab',{attrs:{"title":"Актуальное"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","flex-wrap":"wrap","gap":"10px"}},_vm._l((_vm.newsCards),function(d){return _c('div',{key:d.id,staticClass:"news-card"},[_c('div',{on:{"click":function($event){_vm.callStore(d.id);
              _vm.$router.push('/newsfull');}}},[_c('img',{attrs:{"src":d.featured_image.url,"alt":""}}),_c('div',{staticClass:"news-items"},[_c('p',{staticClass:"nc-text"},[_vm._v(_vm._s(d.title))]),_c('p',{staticClass:"nc-date"},[_vm._v(" "+_vm._s(new Date(d.modified).getDate() + " " + _vm.getMonthName(new Date(d.modified).getMonth()) + " " + new Date(d.modified).getFullYear())+" ")])])]),(!_vm.clicked.includes(d))?_c('div',{staticClass:"like-btn-active",on:{"click":function($event){_vm.clicked.push(d);
              _vm.like(d, 1);}}}):_c('div',{staticClass:"like-btn",on:{"click":function($event){return _vm.like(d, 0)}}})])}),0)]),_c('tab',{attrs:{"title":"Избранное"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","flex-wrap":"wrap","gap":"10px"}},_vm._l((_vm.clicked),function(d,index){return _c('div',{key:d.id,class:['news-card', { active: index === 0 }]},[_c('div',{on:{"click":function($event){_vm.callStore(d.id);
              _vm.$router.push('/newsfull');}}},[_c('img',{attrs:{"src":d.featured_image.url,"alt":""}}),_c('div',{staticClass:"news-items"},[_c('p',{staticClass:"nc-text"},[_vm._v(_vm._s(d.title))]),_c('p',{staticClass:"nc-date"},[_vm._v(" "+_vm._s(new Date(d.modified).getDate() + " " + _vm.getMonthName(new Date(d.modified).getMonth()) + " " + new Date(d.modified).getFullYear())+" ")])])])])}),0)]),_c('tab',{attrs:{"title":"Мультимедиа"}},[_vm._v("Мы готовим для вас интересный контент")])],1),_c('footer',{staticStyle:{"display":"flex","justify-content":"space-between","padding":"5px 10px 10px 25px"}},[_c('div',{staticClass:"left"},[_c('router-link',{attrs:{"to":"/koran"}},[_c('img',{staticClass:"footer-koran footer-icon",attrs:{"src":require("../assets/koran-black.svg"),"alt":""}})]),_c('router-link',{attrs:{"to":"/imam"}},[_c('img',{staticClass:"footer-question footer-icon",attrs:{"src":require("../assets/imam-footer.svg"),"alt":""}})])],1),_c('router-link',{attrs:{"to":"/main"}},[_c('img',{attrs:{"src":require("../assets/menu-logo.svg"),"width":"40","height":"40","alt":""}})]),_c('div',{staticClass:"right"},[_c('router-link',{attrs:{"to":"/calend"}},[_c('img',{staticClass:"footer-calend footer-icon",attrs:{"src":require("../assets/Icon3.svg"),"alt":""},on:{"click":function($event){return _vm.openCalend()}}})]),_c('router-link',{attrs:{"to":"/blago"}},[_c('img',{staticClass:"footer-blagos footer-icon",attrs:{"src":require("../assets/Icon4.svg"),"alt":""}})])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","border-bottom":"1px solid #dadada","padding":"5% 0","gap":"1%","z-index":"1000"}},[_c('div',[_c('p',{staticStyle:{"color":"#2da9a1","font-weight":"700","font-size":"9px","line-height":"11px"}},[_vm._v(" Вопрос имаму ")]),_c('p',{staticStyle:{"font-weight":"400","font-size":"11px","line-height":"13px","color":"#000000"}},[_vm._v(" Получен ответ на вопрос имаму ")])]),_c('p',{staticStyle:{"font-weight":"400","font-size":"9px","line-height":"11px","color":"#363636"}},[_vm._v(" 15:30 - 02.03.2022 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","border-bottom":"1px solid #dadada","padding":"5% 0","gap":"1%","z-index":"1000"}},[_c('div',[_c('p',{staticStyle:{"color":"#2da9a1","font-weight":"700","font-size":"9px","line-height":"11px"}},[_vm._v(" Вопрос имаму ")]),_c('p',{staticStyle:{"font-weight":"400","font-size":"11px","line-height":"13px","color":"#000000"}},[_vm._v(" Получен ответ на вопрос имаму ")])]),_c('p',{staticStyle:{"font-weight":"400","font-size":"9px","line-height":"11px","color":"#363636"}},[_vm._v(" 15:30 - 02.03.2022 ")])])
}]

export { render, staticRenderFns }