<!--TODO: eslint -->
<template>
  <div class="support-page">

    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">
        Правило оказания услуг связи №1
      </div></a
    >
    <div class="root">
      <h1>Правило оказания услуг связи №1</h1>
      <p class="date">Редакция от: 02.03.2022</p>
      <div
        class="sub"
        style="display: flex; justify-content: space-between"
      ></div>
      <img src="../assets/rulefull.jpg" alt="" />
      <p
        style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          line-height: 11px;

          color: #000000;
        "
      >
        Donec at eros hendrerit, tempor felis quis, facilisis dui. Donec sed
        placerat dui, ut convallis nibh. Curabitur rutrum laoreet libero, nec
        scelerisque orci gravida non. Donec imperdiet convallis leo, non
        hendrerit diam fringilla non. Etiam egestas vehicula pharetra. In
        fermentum malesuada efficitur. Maecenas id dapibus ipsum. Donec et
        sapien vitae metus varius lobortis. Duis libero turpis, euismod eu
        congue quis, euismod ut massa. Ut eget lectus tempus, pellentesque
        tellus non, consequat ex.
        <br />
        <br />
        Aenean tincidunt mauris nec ipsum laoreet dapibus. Integer et blandit
        justo. Ut egestas, diam ut ornare rhoncus, est metus blandit libero, id
        sodales augue metus et sapien. Praesent a varius ante, sed sodales nibh.
        Maecenas pharetra posuere sapien, nec sodales velit efficitur a.
        Vestibulum at nisi sagittis, aliquet sem vitae, elementum sapien.
        Suspendisse in efficitur risus. Phasellus porta non neque in dapibus.
        Duis aliquam facilisis vulputate.
      </p>
    </div>

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/calend-active.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "HelloWorld",
  data: () => ({
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
  }),
  methods: {
    back() {
      this.$emit("back", false);
    },
  },
};
</script>
<style scoped lang="scss">
.root {
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 5%;
  gap: 10px;
}
.date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;

  color: #363636;
}
h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  color: #2da9a1;
}
.sub p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;

  color: #363636;
}
.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}
.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}
.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}
.header-icon {
  margin-right: 10px;
}
.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}
header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}
.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}
.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
ul {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}
ul li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}
</style>
