<template>
  <div :class="currentIsNearest && 'nearest'" class="wrapper">
    <div class="line"></div>
    <div class="info-wrapper">
      <div class="name">
        {{ namazMap[data[0]] }}
      </div>
      <div class="time">{{ time }}</div>
      <div v-if="currentIsNearest" class="time-left">
        <div>Осталось</div>
        <div>{{ timeLeft }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { NAMAZ_MAP } from "@/utils/constants";
export default {
  name: "NamazTimelineItem",
  props: ["data", "namazData", "setNearestNamaz"],
  data() {
    return {
      namazMap: NAMAZ_MAP,
    };
  },
  methods: {
    getFormattedTime(hours, minutes) {
      return hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
    },
    getDiffBetweenNamazAndCurrent(namazTime) {
      const currentDate = new Date();

      return namazTime - currentDate.getTime();
    },
    getNamazTime(s) {
      // calculated time according to GMT+0
      return new Date(s * 1000).getTime() - 60 * 60 * 3 * 1000;
    },
  },

  computed: {
    nearest() {
      for (let i = 0; i < this.namazData.length; i++) {
        const namazTime = this.getNamazTime(this.namazData[i][1]);

        if (this.getDiffBetweenNamazAndCurrent(namazTime) > 0) {
          return this.namazData[i];
        }
      }
      return this.namazData[0];
    },
    timeLeft() {
      const namazNerestSeconds = this.getNamazTime(this.nearest[1]);
      let diff = this.getDiffBetweenNamazAndCurrent(namazNerestSeconds) / 1000;

      const minutesFull = Math.abs(Math.round(diff / 60));
      const hours = Math.abs(Math.floor(minutesFull / 60));
      const minutes = minutesFull - hours * 60;
      return this.getFormattedTime(hours, minutes);
    },
    currentIsNearest() {
      return this.nearest[1] === this.data[1];
    },
    time() {
      let date = new Date(this.getNamazTime(this.data[1]));

      return this.getFormattedTime(date.getHours(), date.getMinutes());
    },
  },
  mounted: function () {
    this.setNearestNamaz(document.querySelector(".nearest"));
  },
};
</script>
<style lang="scss">
.nearest {
  .info-wrapper {
    flex-shrink: 0;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background: white;
    padding: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .name {
      color: #333;
      font-size: 11px;
      font-weight: bold;
    }
    .time {
      color: #39b2ba;
    }
    .time-left {
      font-size: 0.8rem;
      color: #333;
      div:last-child {
        color: #39b2ba;
      }
    }
  }
}
.name,
.time {
  font-size: 1rem;
  font-weight: bold;
}

.line {
  height: 1.3rem;
  border: 1px solid white;
  width: 1px;
  margin: 0.2rem;
  flex-shrink: 0;
}
.wrapper {
  padding: 0.4rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: column;
}
</style>
