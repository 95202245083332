<template>
  <div class="main-page">
    <div
      @click="showMenu = false"
      v-if="showModal || showMenu || showNots || showShare"
      style="
        z-index: 999;
        position: absolute;
        display: inline;
        top: -20%;
        left: 0;
        width: 100%;
        height: 200vh;
        opacity: 0.3;
        background-color: black;
        overflow-y: hidden;
      "
    ></div>
    <ul
      v-if="showMenu"
      style="
        z-index: 10000;
        position: fixed;
        width: 70%;
        right: 0;
        bottom: 0;
        top: 0;
        background: white;
      "
    >
      <li>
        <router-link to="/news">Новости</router-link>
      </li>
      <li>
        <router-link to="/tranz">Транзакции</router-link>
      </li>
      <li>
        <router-link to="/myconn">Моя связь</router-link>
      </li>
      <li>
        <router-link to="/tariff">Мой тариф</router-link>
      </li>
      <li>
        <router-link to="/supportnav2">Техподдержка</router-link>
      </li>
    </ul>
    <div
      v-if="showModal"
      class="ayat-modal"
      style="
        position: absolute;
        padding: 4%;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 0;
        left: 5%;
        bottom: -40%;
        height: min-content;
        width: 90%;
        background-color: white;
        z-index: 10000000;
      "
    >
      <img
        @click="showModal = false"
        style="position: absolute; right: 5%"
        src="../../assets/X.svg"
        alt=""
      />
      <h1>Аят дня</h1>
      <span>Сура {{ ayatData.surah_name }}, аят {{ ayatData.stih }}:</span>
      <p>{{ ayatData.text }}</p>

      <div class="modal-controls">
        <img src="../../assets/ReadMore.svg" alt="" />
        <router-link to="/ayat">
          <p @click="callStore(ayatData.surah, ayatData.stih)">
            Открыть в Коране
          </p>
        </router-link>
      </div>
    </div>

    <div
      v-if="showShare"
      class="ayat-modal"
      style="
        position: absolute;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 20%;
        left: 10%;
        bottom: 5%;
        height: min-content;
        width: 80%;
        background-color: white;
        z-index: 1000;
        padding: 5%;
      "
    >
      <img
        @click="showShare = false"
        style="position: absolute; right: 5%"
        src="../../assets/X.svg"
        alt=""
      />

      <p
        style="
          color: #2da9a1;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
        "
      >
        Поделиться в соцсетях
      </p>

      <img style="margin-top: 10%" src="../../assets/shareicons.svg" alt="" />
    </div>

    <div
      v-if="showNots"
      style="
        position: fixed;
        margin-top: 18.5%;
        z-index: 1000;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        top: 0;
        text-align: left;
        right: 0;
        bottom: 5%;
        height: min-content;
        width: 80%;
        background-color: white;
        padding: 8% 5%;
      "
    >
      <img
        @click="showNots = false"
        style="position: absolute; right: 5%"
        src="../../assets/X.svg"
        alt=""
      />
      Функционал в разработке
    </div>
    <div class="namaz-time__container">
      <router-link to="/city">
        <div
          style="display: flex; justify-content: space-around"
          class="namaz-header"
        >
          <p style="padding-top: 7px">Время намаза</p>
          <p
            class="cityName"
            style="
              height: min-content;
              width: 130px;
              margin: 0 3px;
              word-wrap: break-word;
              box-sizing: border-box;
              background-color: white;
              color: rgb(57, 178, 186);
              padding: 10px 20px;
            "
          >
            {{ $store.getters.getCitySelectedName }}
          </p>

          <p style="padding-top: 7px">
            {{
              new Date().toLocaleString("ru", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}
          </p>
        </div>
      </router-link>
      <div class="namaz-timeline">
        <NamazTimelineItem
          :setNearestNamaz="setNearestNamaz"
          v-for="timelineItem in namazData"
          :data="timelineItem"
          :namazData="namazData"
          :key="timelineItem[0]"
        ></NamazTimelineItem>
      </div>
    </div>
    <div class="daily-ayat__container">
      <h1>Аят дня</h1>
      <p class="daily-ayat">
        Сура {{ ayatData.surah_name }}, аят {{ ayatData.stih }}:
      </p>
      <p class="ayat-text">{{ ayatData.text }}</p>
      <div class="buttons">
        <div @click="showModal = true" class="button read-button">Читать</div>
      </div>
    </div>
    <div class="info-cards" style="display: flex">
      <div class="info-card">
        <img width="25" height="25" src="../../assets/Vector.svg" alt="" />
        <p class="ic-text">Сабиль для слов и дел</p>
      </div>
      <div class="info-card">
        <img width="25" height="25" src="../../assets/mechet.svg" alt="" />
        <p class="ic-text">Сабиль – выгодная мобильная связь</p>
      </div>
      <div class="info-card">
        <img width="25" height="25" src="../../assets/Vector.svg" alt="" />
        <p class="ic-text">Сабиль – полезный функционал</p>
      </div>
    </div>

    <h2 class="heading">Новости и события</h2>
    <router-link to="/newsfull">
      <div class="news">
        <div
          @click="setPostStore(d.id)"
          v-for="d in pages"
          :key="d.id"
          class="news-card"
        >
          <img :src="d.featured_image.url" alt="" />
          <div class="news-items">
            <p class="nc-text">{{ shorter(d.title) }}</p>

            <p class="nc-date">
              {{ getStringDateForDate(d.modified) }}
            </p>
            <div class="like-btn__empty"></div>
          </div>
        </div>
      </div>
    </router-link>

    <div>
      <h2 class="heading">Кошелек</h2>
      <div class="wallet">
        <div class="info" style="display: flex; gap: 10px">
          <div class="wallet-card wallet-info">
            <h3>Связь</h3>
            <p class="minutes">
              Остаток {{ balance.UNIT_VOICE }}:
              <span> {{ balance.BAL_VOICE }} из 300</span>
            </p>
            <p class="traff">
              Остаток {{ balance.UNIT_INTERNET }}:
              <span> {{ balance.BAL_INTERNET }} из 100гб</span>
            </p>

            <p class="sms">
              Остаток {{ balance.UNIT_SMS }}:
              <span>{{ balance.BAL_SMS }} из 500</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
          />
        </router-link>
        <router-link to="/imam"
          ><img
            src="../../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
          />
        </router-link>
      </div>
      <router-link to="/main">
        <img src="../../assets/menu-logo.svg" width="40" height="40" alt="" />
      </router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
          />
        </router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
// import VueCountdown from "@chenfengyuan/vue-countdown";
import axios from "axios";
import store from "../../store";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import TimeComp from "../../components/TimeComp.vue";
import NamazTimelineItem from "../../components/NamazTimelineItem.vue";
import { getAllDataMainPage } from "@/api/api";
import { getStringDateForDate } from "@/utils/helpers";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { TimeComp, NamazTimelineItem },
  name: "HelloWorld",
  data: () => ({
    balance: {
      UNIT_CORE: "руб.",
      BAL_CORE: 48.5,
      UNIT_BONUS: "бонусов",
      BAL_BONUS: 0,
      UNIT_VOICE: "мин.",
      BAL_VOICE: 0,
      UNIT_SMS: "SMS",
      BAL_SMS: 0,
      UNIT_INTERNET: "Гб.",
      BAL_INTERNET: 0,
      ok: true,
    },
    fetchNamazTimer: null,
    fetched: false,
    ayatData: {},
    namazData: {},
    pages: {},
    hasMatch: false,
    showMenu: false,
    page125: {
      title: "Test p1232ost",
      date: 1644324231,
      featured_image: {
        url: "http://sabeel.credo.ru/wp-content/uploads/2022/02/84002881_519530148668789_3553100422154995993_qn.jpg",
        title: "Тестовый Заголовок Картинки",
        region: "Это Регион",
      },
      text: "dsassdsassaddsadasdsd\n",
      ok: true,
    },
    showModal: false,
    namazMap: {
      Dhuhr: "Зухр",
      Fajr: "Фаджр",
      Asr: "Аср",
      Maghrib: "Магриб",
      Sunrise: "Восход",
      Ishaa: "Иша",
    },
    showShare: false,
    variable: "first",
    showNots: false,
    nearestNamaz: null,
  }),
  methods: {
    getStringDateForDate,
    callStore(s, st) {
      localStorage.setItem("ayat", this.ayatData.stih);
      localStorage.setItem("selected", this.ayatData.surah - 1);
      store.commit("SET_AYAT", {
        surah: s,

        stih: st,
      });
    },
    setNearestNamaz(val) {
      this.nearestNamaz = val;
    },
    getMonthName(month) {
      const monthNames = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      return monthNames[month];
    },
    setPostStore(x) {
      store.commit("SET_POST", x);
    },
    like() {
      console.log("liked");
      return;
    },
    ask() {
      this.$emit("goAsk", false);
    },
    goSupport() {
      this.$emit("goSupport", false);
    },
    openCalend() {
      this.$emit("openCalend", false);
    },
    shorter(s) {
      if (s.length > 10) {
        let str = s.slice(0, 12);
        return `${str}...`;
      } else {
        return s;
      }
    },
    async fetchNamaz() {
      let data2 = `city=${store.getters.getCitySelected}`;
      const namazResp = await axios.post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/prayer_times", "data": ${JSON.stringify(
          data2
        )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"}, "cookies": {"PHPSESSID": "null"}}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      this.namazData = namazResp.data;
      const namazArr = Object.entries(this.namazData);
      const filteredArray = namazArr.filter((el) => el?.[0] in this.namazMap);
      filteredArray.sort((a, b) => a?.[1] - b?.[1]);
      this.namazData = filteredArray;
    },
  },

  computed: {
    items() {
      return this.options.filter((i) => i.title !== this.selectedLang?.title);
    },
  },
  beforeMount() {},
  mounted: function () {
    this.fetchNamaz();

    this.fetchNamazTimer = setInterval(() => {
      this.fetchNamaz();
    }, 10000);
  },
  watch: {
    fetched(newval) {
      if (newval) {
        store.dispatch("updateArray");
      }
    },
    nearestNamaz(newValue) {
      if (newValue) newValue.scrollIntoView({ behavior: "smooth" });
    },
  },
  beforeDestroy() {
    clearInterval(this.fetchNamazTimer);
  },

  async created() {
    const MAX_NEWS_SIZE = 30;
    let that = this;
    const data3 = "category_id=" + 6;
    const [ayatData, balanceData, pagesData] = await getAllDataMainPage(
      data3,
      JSON.parse(localStorage.getItem("sessid"))
    );
    that.ayatData = ayatData;
    that.balance = balanceData;
    that.fetched = true;
    that.pages = pagesData.pages.sort(
      (a, b) => new Date(a).getTime() > new Date(b).getTime()
    );
    that.pages.length = Math.min(pagesData.pages.length, MAX_NEWS_SIZE);
  },
};
</script>
<style scoped lang="scss">
@import "main-page.styles";
</style>
