<!--TODO: eslint -->
<template>
  <div class="main-page">
    <!-- <div
      @click="showMenu = false"
      v-if="showModal || showMenu || showNots || showShare || showSubModal"
      style="
        z-index: 999;
        position: absolute;
        display: inline;
        top: -20%;
        left: 0;
        width: 100%;
        height: 200vh;
        opacity: 0.3;
        background-color: black;
        overflow-y: hidden;
      "
    ></div>
    <ul
      v-if="showMenu"
      style="
        z-index: 10000;
        position: fixed;
        font-size: 15px !important;
        padding: 5% !important;
        width: 70%;
        right: 0;
        bottom: 0;
        top: 0;
        background: white;
      "
    >
      <li><router-link to="/news">Новости</router-link></li>
      <li><router-link to="/tranz">Транзакции</router-link></li>
      <li><router-link to="/myconn">Моя связь</router-link></li>

      <li><router-link to="/supportnav">Техподдержка</router-link></li>
    </ul> -->
    <div
      @click="
        showMenu = false;
        showAsk = false;
      "
      v-if="showModal || showAsk"
      style="
        z-index: 999;
        position: absolute;
        display: inline;
        top: -20vh;
        left: 0;
        width: 100%;
        height: 170vh;
        opacity: 0.3;
        background-color: black;
        overflow-y: hidden;
      "
    ></div>
    <div
      v-if="showNots"
      style="
        position: fixed;
        margin-top: 20%;
        z-index: 1000;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        top: 0;
        text-align: left;
        right: 0;
        bottom: 5%;
        height: min-content;
        width: 80%;
        background-color: white;
        padding: 8% 5%;
      "
    >
      <img
        @click="showNots = false"
        style="position: absolute; right: 5%"
        src="../assets/X.svg"
        alt=""
      />

      <p
        style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;

          color: #000000;
          margin-bottom: 5%;
        "
      >
        Последние непрочитанные уведомления
      </p>
      <div
        style="
          display: flex;
          border-bottom: 1px solid #dadada;
          padding: 5% 0;
          gap: 1%;
          z-index: 1000;
        "
      >
        <div>
          <p
            style="
              color: #2da9a1;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
            "
          >
            Вопрос имаму
          </p>
          <p
            style="
              font-weight: 400;
              font-size: 11px;
              line-height: 13px;

              color: #000000;
            "
          >
            Получен ответ на вопрос имаму
          </p>
        </div>

        <p
          style="
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            color: #363636;
          "
        >
          15:30 - 02.03.2022
        </p>
      </div>
      <div
        style="
          display: flex;
          border-bottom: 1px solid #dadada;
          padding: 5% 0;
          gap: 1%;
          z-index: 1000;
        "
      >
        <div>
          <p
            style="
              color: #2da9a1;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
            "
          >
            Вопрос имаму
          </p>
          <p
            style="
              font-weight: 400;
              font-size: 11px;
              line-height: 13px;

              color: #000000;
            "
          >
            Получен ответ на вопрос имаму
          </p>
        </div>

        <p
          style="
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            color: #363636;
          "
        >
          15:30 - 02.03.2022
        </p>
      </div>
      <router-link to="/nots"
        ><p
          style="
            color: #39b2ba;
            text-align: right;
            font-size: 9px;
            line-height: 11px;
            margin-top: 3%;
          "
        >
          Посмотреть все уведомления
        </p></router-link
      >
    </div>
    <div
      v-if="showModal"
      class="subModal"
      style="
        position: absolute;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 25%;
        left: 10%;
        bottom: 5%;
        height: min-content;
        width: 80%;
        text-align: left;
        background-color: white;
        z-index: 1000;
        padding: 8%;
      "
    >
      <img
        @click="showModal = false"
        style="position: absolute; right: 5%; top: 5%"
        src="../assets/X.svg"
        alt=""
      />
      <h1>Оформить подписку</h1>
      <p>
        Оформите подписку, чтобы задавать вопросы и звонить имаму без
        ограничений
      </p>
      <div>
        <p>Стоимость услуги</p>
        <p style="font-weight: bold">49 ₽/мес</p>
      </div>
      <button>Оформить подписку</button>
    </div>
    <div
      v-if="showAsk"
      class="subModal"
      style="
        position: absolute;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 15%;
        left: 10%;
        bottom: 5%;
        height: min-content;
        width: 80%;
        text-align: left;
        background-color: white;
        z-index: 1000;
        padding: 8%;
      "
    >
      <img
        @click="showAsk = false"
        style="position: absolute; right: 5%; top: 5%"
        src="../assets/X.svg"
        alt=""
      />

      <!-- <h1 style="margin:4%">Тема вопроса</h1>
   <select name="" id="">
                    <option value="lorem">lorem</option>
                    <option value="lorem">ipsumm</option>
                    <option value="lorem">sitamet</option>
                </select> -->
      <h1 style="margin: 4%">Ваш вопрос</h1>
      <textarea v-model="question" name="" id="" cols="30" rows="10"></textarea>
      <button
        @click="
          sendQuestion();
          showAsk = false;
          showSubModal = true;
        "
      >
        Отправить вопрос
      </button>
    </div>
    <div
      v-if="showSubModal"
      class="subModal"
      style="
        position: absolute;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 15%;
        left: 10%;
        bottom: 5%;
        height: min-content;
        width: 80%;
        text-align: center;
        background-color: white;
        z-index: 1000;
        padding: 8%;
      "
    >
      <img
        @click="showSubModal = false"
        style="position: absolute; right: 5%; top: 5%"
        src="../assets/X.svg"
        alt=""
      />
      <img src="../assets/approve.svg" alt="" />
      <h1>Успешно</h1>
      <p style="font-size: 16px; line-height: 18px">
        Вопрос отправлен имаму. Когда ответ будет получен, Вы сможете увидеть
        его в разделе “мои вопросы”
      </p>

      <button onClick="javascript:history.go(-1); return false">
        Вернуться
      </button>
    </div>
    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Вопрос имаму</div></a
    >
    <div class="daily" style="margin-top: 2%">
      <div class="daily-left">
                <img width="100%" height="100%" src="../assets/imam_image.svg" alt="" />
      </div>
      <div class="daily-right">
        <h1
          style="
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            margin-top: 10%;
            line-height: 17px;
            text-transform: uppercase;

            color: #2da9a1;
          "
        >
          Совет дня от имама:
        </h1>
        <p
          style="
            font-family: 'Montserrat';
            font-style: italic;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;

            color: #000000;
          "
        >
          И знайте, что самым чистым ликованием для души и сердца человека
          является душевное наслаждение, происходящее от любви к Всевышнему
        </p>
      </div>
    </div>
    <hr class="separator_line" />

    <p class="additional_info">
      Функционал в разработке. Здесь вы сможете задать вопрос и получить
      онлайн-консультацию имама.
    </p>
    <div style="position: relative" class="buttons">
      <!-- <button @click="showAsk = true">Задать вопрос</button>
      <img
        style="position: absolute; left: 11%; top: 10px"
        src="../assets/mini-vopros.svg"
        alt=""
      /> -->
      <!-- <button style="width:300px">Звонок имаму</button> -->
    </div>
    <!-- <button
      @click="showModal = true"
      style="
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        border: 0.5px solid #39b2ba;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        padding: 2% 20%;
        margin: 3% auto;
        color: #39b2ba;
      "
    >
      Оформить подписку
    </button> -->

    <!-- <tabs :mode="mode">
      <tab title="Популярные">
        <PopularQuestion />
      </tab>
      <tab title="Мои вопросы"></tab>
    </tabs> -->

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/ask-active.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
// import Tabs from "./NewsTabs.vue";
// import Tab from "./NewsTab.vue";
// import PopularQuestion from "./PopularQuestion.vue";
import axios from "axios";
export default {
  // components: { Tab, Tabs, PopularQuestion },
  name: "ImamComponent",
  data: () => ({
    question: "",
    showMenu: false,
    showAsk: false,
    showModal: false,
    showSubModal: false,
    showShare: false,
    showNots: false,
  }),
  methods: {
    sendQuestion() {
      const data3 = `question=  '${this.question}'`;
      axios
        .post(
          "https://api.sabil.webinfra.ru/sabil",
          `{"path": "https://api.sabeel.credo.ru:5543/api/new_iquestion", "data": ${JSON.stringify(
            data3
          )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"}, "cookies": {"PHPSESSID": "${
            this.$store.getters.getPin
          }"}}`,
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then(function (response) {
          // cookieJar.myCookies = response.headers['Content-Type'];
          // console.log(cookieJar.myCookies)
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
    },
    like() {
      console.log("liked");
      return;
    },
    ask() {
      this.$emit("goAsk", false);
    },
    goSupport() {
      this.$emit("goSupport", false);
    },
    openCalend() {
      this.$emit("openCalend", false);
    },
    back() {
      this.$emit("back", false);
    },
  },
  computed: {
    items() {
      return this.options.filter((i) => i.title !== this.selectedLang?.title);
    },
  },
};
</script>
<style scoped lang="scss">
.subModal p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: black !important;
  margin-top: 8px;
}
.subModal div {
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  padding-bottom: 5px;
}
.subModal button {
  color: #39b2ba;
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  height: 40px;
  margin: 5% auto;
  margin-bottom: 0;
}
ul {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}
ul li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}
textarea {
  margin: 7px 0;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 27px;
  height: 100%;
  padding: 5%;
  width: 100%;
  resize: none;
}
.select {
  margin: 10%;
  padding: 30px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  position: relative;
  height: min-content;
}
select {
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-transform: capitalize;
  color: #000000;
  width: 100%;
}
.subModal h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #2da9a1;
  text-transform: uppercase;
}
.subModal p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #636363;
  margin-top: 8px;
}
.subModal div {
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  border-bottom: 0.5px solid #afafaf;
  color: #000000;
  padding-bottom: 5px;
}
.subModal button {
  color: #39b2ba;
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  height: 40px;
  margin: 5% auto;
  margin-bottom: 0;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.buttons button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 40%;
  height: 30px;
  background: rgba(57, 178, 186, 0.2);
  color: #39b2ba;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.separator_line {
  margin-top: 8px;
  border: 0.5px solid #529888;
}

.additional_info {
  margin: 100px 38px 0 38px;
  font-weight: 500;
  text-align: center;
  color: rgb(45, 169, 161);
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
}

.daily-right h1 {
  //  padding-top: 5px;
  // padding-right: 15%;
  margin-bottom: 5%;
  position: relative;
}
.daily-right h1::before {
  content: "";
  position: absolute;
  width: 2em;
  height: 2em;
  top: 1.5em;
  left: -2em;
  background-image: url("../assets/quotes.svg");
}
.daily-right p {
  position: relative;
}
.daily-right p::after {
  content: "";
  position: absolute;
  width: 3em;
  height: 3em;
  right: -1em;
  bottom: -2.5em;
  background-image: url("../assets/quotes2.svg");
}

.daily-right {
  width: 50%;
}
.daily-left {
  width: 50%;
}
.daily-left img {
  background: #ffffff;
  margin-right: 10%;
}
.daily-left p {
  margin-top: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  line-height: 17px;

  color: #878787;
}
.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.daily {
  padding: 13px;
  display: flex;
}
.user {
  display: flex;
  align-items: flex-end;
}
.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}
.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}
.header-icon {
  margin-right: 10px;
}
.main-page {
  // padding:10px;
  margin-top: 12%;
  margin-bottom: 50%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 140vh;
  padding-bottom: 300px !important;
  background-repeat: repeat;
  position: relative;
}
header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));
  top: 0;
}
.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}
.user,
.controls {
  width: min-content;
  margin: 0;
}
footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
.tabs__header {
  font-size: 15px !important;
}
</style>
