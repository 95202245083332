<!--TODO: eslint -->
<template>
  <div class="support-page">


    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Коран</div></a
    >

    <ul>
      <li>
        <div style="width:250px;text-align: right;" ><h1 dir="rtl" v-html="ayatArabic"></h1></div>
       
        <div class="numberCircle">{{ ayatData.stih }}</div>

      </li>

      <p class="koranfont">{{ ayatData.text }}</p>
      <!-- <p>Sed sed massa ornare, venenatis dolor tempor, semper metus. Proin sapien eros, semper a lacus non, venenatis rhoncus mi. Donec rhoncus elit eget sapien auctor molestie. Aliquam erat volutpat. Maecenas tortor arcu, blandit in enim eget, ornare scelerisque lorem. Nullam maximus ac nisl quis fermentum. Sed tincidunt, arcu quis consectetur hendrerit, nisi orci ornare eros, sit amet vehicula libero tellus sit amet nulla. Phasellus semper interdum ultrices. Aenean in venenatis enim.
</p>
<br>
<p>Proin mollis est id venenatis ullamcorper. Proin nisi est, rhoncus at massa eget, cursus convallis est. Phasellus elementum imperdiet molestie. Quisque a lobortis erat. Etiam vitae mattis magna. Nulla in odio eu ligula ultrices aliquam non eget nulla. Quisque eu nunc in massa facilisis consectetur. Sed venenatis quam quam. Integer dui elit, maximus id orci a, elementum ultrices mi. Fusce vulputate condimentum commodo. Integer eget turpis lacinia, porta urna eget, accumsan nunc. Nunc lobortis ante magna, at elementum augue tempus aliquam. Vivamus tristique dignissim dui, eget venenatis diam lacinia et.</p> -->
    </ul>

    <div
      style="z-index: 1000; background-color: #fff"
      v-if="showSelect"
      class="select"
    >
      <img
        @click="showSelect = false"
        src="../assets/X.svg"
        width="10"
        style="position: absolute; right: 20px"
        alt=""
      />
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <button>Сохранить</button>
    </div>

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-active.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import axios from "axios";
// import store from "../store/index";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "HelloWorld",
  data: () => ({
    ayatArabic: null,
    ayatData: "",
   
    showSelect: false,

  }),
  methods: {
  
    back() {
      this.$emit("back", false);
    },
    shorter(arabic){
      let str = arabic
      return str
      // console.log(str)
    }
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  async created() {
    // let newDate = (new Date().getTime()/1000).toFixed()
    let that = this;
let x = +localStorage['selected'] + 1
    let data2 = `surah=${x}`;
    await axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/quran", "data": ${JSON.stringify(
          data2
        )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID": "null"}}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response) {
        // cookieJar.myCookies = response.headers['Content-Type'];
        // console.log(cookieJar.myCookies)
        that.ayatData = JSON.parse(JSON.stringify(response.data.quran));

        // that.namazData.sort(function(a,b){
        //   return a-b
        // })
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
      that.ayatArabic = that.ayatData.filter((x) => {
      return x.stih == localStorage.getItem('ayat');
    })[2]?.text;

    that.ayatData = that.ayatData.filter((x) => {
      return x.stih == localStorage.getItem('ayat');
    })[1];
    
  },
};
</script>
<style scoped lang="scss">
@font-face {
    font-family: 'koranFont';
    src: url('../fonts/ScheherazadeNew-Regular.ttf');
}
p.koranfont {
  // font-family: 'koranFont' !important;
  letter-spacing: 1px;
  padding-bottom: 100px;
}
h1 {
  font-family: 'koranFont' !important;
}
  .numberCircle {
    border-radius: 50%;
  width: 50px !important;
  line-height: 49px;
  text-align: center;
  height: 50px !important;
  padding: 0px;

  background: #fff;
  border: 2px solid #2da9a1;
  color: #2da9a1;
  text-align: center;

  font-size: 20px;
}
ul {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}
ul li {
  // border-bottom: 0.5px solid #AFAFAF;
  // padding: 3%;
}
img {
  justify-self: flex-end;
}
button {
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  width: 80%;
  padding: 10px;
  margin: 0 auto;
  color: #39b2ba;
}
.select {
  margin: 10%;
  margin-top: 5%;
  position: absolute;
  top: -30%;
  padding: 30px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  position: relative;
  height: min-content;
}
select {
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-transform: capitalize;
  color: #000000;
  width: 100%;
}
.select-item {
  display: inline-flex;
  margin: 5% auto;
}
label {
  text-align: left;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: uppercase;

  color: #2da9a1;
}
ul {
  padding: 10px;
}
ul li {
  display: flex;

  // padding: 2% 10%;
  // border-bottom: 1px solid #DADADA;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
}
ul li p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #000000;
}
.searchbar input {
  background: #ffffff;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 70%;
  padding: 3px;
  font-weight: 400;
  font-size: 12px;
  padding: 3%;
  line-height: 15px;
  color: #a8a8a8;
  // position: relative;
}
.searchbar {
  position: relative;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.select {
  z-index: 1000;
}

.user {
  display: flex;
  align-items: flex-end;
}
.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}
.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}
.header-icon {
  margin-right: 10px;
}
.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}
header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}
.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
ul li div h1 {
  font-size: 20px !important;
  width: 300px;
  // letter-spacing: 5px;
  margin-right: 0px;
  color: #2da9a1;
  line-height: 40px !important;
  display: inline;
} 

ul li div {
  text-align: left;
}
p {
  font-size: 17px;
  line-height: 23px;
}
</style>
