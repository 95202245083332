<!--TODO: eslint -->
<template>
  <div class="header">
    <img class="logo" src="../assets/homelogoo.png" alt="" />

    <img class="sabil" src="../assets/sabi.svg" alt="" />
    <img class="dsid" src="../assets/dlyaslov.svg" alt="" />

    <router-link to="/auth1">
      <button @click="login()" id="login" style="display: block">
        Войти в приложение
      </button></router-link
    >
    
    <div class="header-mask"></div>
 
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  methods: {
    login() {
      this.$emit("eventname", false);
    },
  },
};
</script>
<style scoped lang="scss">
img {
  margin: 0 auto;
}
.header {
  height: 100%;
  background-color: #39b2ba;
  position: fixed;
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}
.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.1;
  height: 100%;
  background-size: cover;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}
.logo {
  margin-top: 20%;
}
.sabil {
  margin-top: 10%;
  margin-bottom: 2%;
}
.dsid {
  margin-bottom: 20%;
}
#login {
  margin: 0 auto;
  background: white;
  padding: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 50px;
  width: 274px;
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  border-radius: 7px;
}
</style>
