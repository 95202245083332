<!--TODO: eslint -->
<template>
  <div class="support-page">
    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Благодеяния</div></a
    >

    <div class="help">
      <img src="../../assets/help.svg" alt="" />
      <p>Благотворительность. Рассказываем как помочь</p>
    </div>
    <router-link to="/stats"
      ><p class="stats">Статистика голосований</p>
    </router-link>

    <p
      style="
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;

        margin: 2% 5%;
        text-align: center;
        text-transform: uppercase;

        color: #2da9a1;
      "
    >
      Проекты
    </p>

    <router-link to="/blagofull"
      ><div
        class="projects"
        style="
          padding: 2%;
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: wrap;
          padding-bottom: 160px;
          /* justify-content:left */
        "
      >
        <div
          @click="callStore(d.id)"
          v-for="d in pages"
          :key="d.id"
          class="project-card"
          v-bind:class="d.liked ? 'liked' : null"
        >
          <img :src="d.featured_image.url" alt="" />
          <div
            style="
              padding: 0 10px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            "
          >
            <h1>{{ d.title }}</h1>
            <p
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3;
                -webkit-box-orient: vertical;
              "
            >
              {{ d.text }}
            </p>
            <div class="like_container">
              <p class="like_quant">
                Проголосовали {{ d.like_count }} человека
              </p>
              <div
                v-if="d.liked"
                v-on:click.stop.prevent="like(d, 0)"
                class="like-btn"
              />
              <div
                v-if="!d.liked"
                v-on:click.stop.prevent="like(d, 1)"
                class="like-btn__empty"
              />
            </div>
          </div>
        </div></div
    ></router-link>
    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../../assets/blago-active.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import store from "../../store";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import { getArticle, getPagesData, likePage } from "@/api/api";
export default {
  name: "BlagoPage",
  data: () => ({
    pages: "",
  }),
  methods: {
    callStore(x) {
      store.commit("SET_BLAGO", x);
    },

    async like(d, action) {
      const { data } = await likePage(
        d,
        action,
        JSON.parse(localStorage.getItem("sessid"))
      );
      const selectedIndex = this.pages.findIndex(
        ({ id }) => id === data.page_id
      );
      const selectedPage = this.pages.find(({ id }) => id === data.page_id);
      const modifiedPage = {
        ...selectedPage,
        liked: data.liked,
        like_count: data.like_cnt,
      };
      this.pages = [
        ...this.pages.slice(0, selectedIndex),
        modifiedPage,
        ...this.pages.slice(selectedIndex + 1),
      ];
    },

    back() {
      this.$emit("back", false);
    },

    async getPageDetail(pageId) {
      const id = "page_id=" + pageId;
      const { data } = await getArticle(
        id,
        JSON.parse(localStorage.getItem("sessid"))
      );
      return data;
    },
  },

  async created() {
    const params = "category_id=" + 8;
    const response = await getPagesData(
      params,
      JSON.parse(localStorage.getItem("sessid"))
    );
    const pages = await response.data.pages;
    await Promise.all(
      pages.map(async (page) => {
        const detail = await this.getPageDetail(page.id);
        page.text = detail.text.slice(0, detail.text.indexOf(".") + 1);
        page.like_count = detail.like_count;
        page.liked = detail.liked;
      })
    );
    this.pages = pages;
  },
};
</script>
<style scoped lang="scss">
@import "blago-page.styles";
</style>
