<!--TODO: eslint -->
<template>
  <div class="suport-page">
    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Новости</div></a
    >
    <ArticleComponent />
  </div>
</template>

<script>
import ArticleComponent from "../../components/ArticleComponent.vue";
export default {
  components: { ArticleComponent },
  name: "NewsPage",
  methods: {
    back() {
      this.$emit("back", false);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped lang="scss">
@import "article-page.styles";
</style>
