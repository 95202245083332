<!--TODO: eslint -->
<template>
  <div style="padding: 60px 0; height: 100%">
    <img src="../assets/logo.png" alt="" />
    <p class="-desc" style="font-size: 15px; color: #ccb282">Шаг 2 из 3</p>
    <p
      class="auth-desc"
      style="font-size: 14px; color: #39b2ba; padding: 0 80px"
    >
      Введите код, отправленный на Ваш номер
    </p>
    <form class="wrap">
    <input
    type="number"
      v-for="(i, idx) in [0, 1, 2, 3]"
      :key="idx"
      maxlength="1"
      :id="'input' + i"
      :value="data[i] || ''"
      @input="onType($event, i)"
      @keyup.delete="onDelete($event, i)"
    />
  </form>
    <!-- <input type="text" maxlength="4"> -->
    <span
      >Повторная отправка кода возможна через
      <strong>{{ minutes }}:{{ seconds }}</strong> секунд</span
    >
    <router-link to="/auth3">
      <button @click="nextStep1()" id="get-password">Войти</button></router-link
    >
    <button @click="nextStep1()" :disabled="seconds > 0" id="get-password">
      Отправить код повторно
    </button>
  </div>
</template>
<script>
import store from "../store/index";
import axios from "axios";
export default {
  data: () => ({
    data: [],
    start: "",
    reloadTime: 0,
    interval: "",
    minutes: "",
    seconds: "",
    end: "",
  }),
  methods: {
    again() {
      let that = this
      let tel = store.getters.getNumberOk;
      console.log(tel);
      var data = "msisdn=" + tel;
      axios
        .post(
          "https://api.sabil.webinfra.ru/sabil",
          `{"path": "https://api.sabeel.credo.ru:5543/api/auth", "data": ${JSON.stringify(
            data
          )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function () {
          that.seconds=30
          that.end = new Date();
          that.end.setTime(that.start + 30000);
          that.timerCount(that.start, that.end);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updateValue(value) {
      console.log(value);
    },
    nextStep1() {
      this.start = new Date().getTime();
      this.end = new Date();
      this.end.setTime(this.start + 30000);
      console.log(this.end, "end");
      this.timerCount(this.start, this.end);
      this.interval = setInterval(() => {
        this.timerCount(this.start, this.end);
      }, 1000); 
      this.$emit("goToAuth3", false);
    },
    timerCount(start, end) {
      console.log(new Date(start), new Date(end));
      let currentTime = new Date().getTime();
      console.log(new Date(currentTime), "currentTime");
      let dist = start - currentTime;
      console.log(dist, "dist");
      let passed = end - currentTime;
      console.log(passed);
      if (dist < 0 && passed < 0) {
        clearInterval(this.interval);
        return;
      } else if (dist < 0 && passed > 0) {
        this.calcTime(passed);
      } else if (dist > 0 && passed > 0) {
        this.calcTime(dist);
      }
    },
    calcTime(x) {
      console.log(x);
      this.minutes = Math.floor((x % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((x % (1000 * 60)) / 1000);
    },
    onType(e, idx) {
      this.data[idx] = e.target.value;

      if (idx < 3 && ("" + this.data[idx]).length > 0)
        document.getElementById(`input${idx + 1}`).focus();
    },
    onDelete(e, idx) {
      if (!e.target.value && idx > 0)
        document.getElementById(`input${idx - 1}`).focus();
    },
  
  },

  mounted() {
    this.start = new Date().getTime();
    this.end = new Date();
    this.end.setTime(this.start + 30000);
    console.log(this.end, "end");
    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
    var obj = document.getElementById('partitioned');
obj.addEventListener('keydown', stopCarret); 
obj.addEventListener('keyup', stopCarret); 

function stopCarret() {
    if (obj.value.length > 3){
        setCaretPosition(obj, 3);
    }
}

function setCaretPosition(elem, caretPos) {
    if(elem != null) {
        if(elem.createTextRange) {
            var range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if(elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            }
            else
                elem.focus();
        }
    }
}
  },
};
</script>
<style scoped lang="scss">
// input {
//    padding:20px 5px;
//   //  border:2px solid;
//    font-family:monospace;
//    outline: none;
//    width: calc(4*(1.1ch + 5px));
//    font-size:50px;
//    letter-spacing:5px;
//    background:
//     repeating-linear-gradient(to right,blue 0 1ch,transparent 1ch calc(1ch + 5px))
//     bottom/200% 2px content-box no-repeat;
// }
// input {
//   font-family: "Montserrat";
//   display: block;
//   margin: 0 auto;
//   // text-align: center;
//   margin-top: 50px;
//   border: none;
//   padding: 0;
//   margin-left: 31%;
//   width: $in-w;
//   background: repeating-linear-gradient(
//       90deg,
//       #39b2ba 0,
//       #39b2ba $char-w,
//       transparent 0,
//       transparent $char-w + $gap
//     ) -10% 100% / #{$in-w - $gap} 2px no-repeat;
//   font: 30px normal sans mono, consolas, Montserrat;
//   letter-spacing: $gap * 1.35;

//   &:focus {
//     outline: none;
//     color: #39b2ba;
//   }
// }
.wrap {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 50px;
}
input {
  width: 40px;
  border: none;
  border-bottom: 1px solid #39b2ba;
  color: #39b2ba;
  font-size: 40px;
  text-align: center;
  outline: none;
}
strong {
  color: #ccb282;
}
span {
  margin-top: 80px !important;
  margin: auto;
  width: 300px;
  display: block;
}
</style>
