<!--TODO: eslint -->
<template>
  <div v-if="logined" class="support-page">
    <div @click="showMenu = false" v-if="showModal || showMenu || showNots || showShare" style="
        z-index: 1;
        position: fixed;
        display: inline;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100vh;
        height: 100vh;
        opacity: 0.3;
        background-color: black;
        overflow-y: hidden;
      "></div>
    <ul v-if="showMenu" style="
        z-index: 10000;
        position: fixed;
        width: 70%;
        right: 0;
        bottom: 0;
        top: 0;
        background: white;
      ">
      <li>
        <router-link to="/news">Новости</router-link>
      </li>
      <li>
        <router-link to="/tranz">Транзакции</router-link>
      </li>
      <li>
        <router-link to="/myconn">Моя связь</router-link>
      </li>

      <li>
        <router-link to="/supportnav2">Техподдержка</router-link>
      </li>
    </ul>

    <div v-if="showNots" style="
        position: fixed;
        margin-top: 20%;
        z-index: 1000;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        top: 0;
        text-align: left;
        right: 0;
        bottom: 5%;
        height: min-content;
        width: 80%;
        background-color: white;
        padding: 8% 5%;
      ">
      <img @click="showNots = false" style="position: absolute; right: 5%" src="../assets/X.svg" alt="" />
      Функционал в разработке
    </div>
        <header-component @toggleNots="showNots = !showNots" @toggleMenu="showMenu = !showMenu"></header-component>
  </div>

</template>
  
<script>

import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "NavBar",
  components: { HeaderComponent },
  data: () => ({
    logined: JSON.parse(localStorage.getItem('sabilLogin')),
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
  }),
};
</script>
<style scoped lang="scss">
.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}

.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  //   height: 100vh;
  background-repeat: repeat;
  position: relative;
}

header {
  background-color: #39b2ba;
  position: fixed;
  z-index: 0;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.header-icon {
  margin-right: 10px;
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

.user {
  display: flex;
  align-items: flex-end;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

ul {
  text-align: left;
  padding: 5%;

  list-style: none;
}

ul li {
  border-bottom: 1px solid #dadada;
  padding: 5%;
  padding-left: 0;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

ul li:first-child {
  padding-top: 0;
}

ul.menu {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}

ul.menu li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}
</style>
  