<!--TODO: eslint -->
<template>
  <div class="circle">
    <div class="arrow">
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.22926 1.07031C8.22926 0.479254 7.75012 0.000105951 7.15906 0.000105977C6.568 0.000106003 6.08885 0.479254 6.08885 1.07031L8.22926 1.07031ZM7.15906 10.7022L13.3379 0.000105826L0.980217 0.000106366L7.15906 10.7022Z"
          fill="#39B2BA"
        />
      </svg>
    </div>
    <div class="timer-title">{{ namazMap[data.name] }}</div>

    <div class="timer">{{ data.time }}</div>
    <div class="timer-subtitle">Осталось</div>
    <!-- <p style="color:red">{{newtimestamp}}</p> -->
    <div class="subtimer">{{ countRemain() }}</div>
  </div>
</template>
<script>
export default {
  props: ["data", "timestamp"],
  data() {
    return {
      title: "Зухр",
      timer: "12:44",
      subtimer: "3ч.3м.",
      namazMap: {
        Dhuhr: "Зухр",
        Fajr: "Фаджр",
        Asr: "Аср",
        Maghrib: "Магриб",
        Sunrise: "Восход",
        Ishaa: "Иша",
      },
    };
  },
  methods: {
    countRemain() {
      let currentDate = new Date();
      let date = new Date(this.timestamp * 1000);
      date.setHours(date.getHours()-3)
      date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        date.getHours(),
        date.getMinutes()
      );
      let result = Math.round(Math.abs(date - currentDate) / 1000 / 60);
      return `${Math.round(result / 60)}:${result % 60}`;
    },
  },
};
</script>
<style lang="scss">
.circle {
  width: 70.03px;
  height: 70.03px;
  background: #ffffff;
  box-shadow: 0px 0px 6.42124px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  .arrow {
    position: absolute;
    left: 50%;
    top: -7px;
    transform: translateX(-50%);
  }
  .timer-title {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
  }

  .timer {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #39b2ba;
  }

  .timer-subtitle,
  .subtimer {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
  }

  .subtimer {
    color: #39b2ba;
  }
}
</style>
