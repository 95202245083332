<!--TODO: eslint -->
<template>
  <div class="support-page">
    <!-- <div @click="showMenu=false" v-if="showModal || showMenu || showNots || showShare" style="z-index: 999;;position:absolute;display:inline;top:-20%; left:0;width:100%; height:120vh;opacity:0.3;background-color:black;overflow-y:hidden"></div> -->
    <div @click="showMenu = false" v-if="showModal || showMenu || showNots || showShare" style="
        z-index: 999;
        position: absolute;
        display: inline;
        top: -20%;
        left: 0;
        width: 100%;
        height: 120vh;
        opacity: 0.3;
        background-color: black;
        overflow-y: hidden;">
    </div>
    <div v-if="showModal" class="subModal" style="
        position: absolute;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: 25%;
        left: 10%;
        bottom: 5%;
        height: min-content;
        width: 80%;
        text-align: left;
        background-color: white;
        z-index: 1000;
        padding: 8%;">
      <img @click="showModal = false;" style="position: absolute; right: 5%; top: 5%" src="../assets/X.svg" alt="" />
      <h1>{{ toggleModal }} услугу</h1>
      <p>
        Вы уверены что хотите {{ toggleModal }} услугу “{{ selectedName }}”?
      </p>
      <!-- <div>
        <p>Стоимость услуги</p>
        <p style="font-weight: bold">{{ priceSelected }} ₽/мес</p>
      </div> -->
      <div style="display:flex; gap:10px"> <button @click="changeService()">Да</button>

        <button v-on:click="showModal = false" style="background-color: white; color:black ">Отмена</button>
      </div>

    </div>


    <a href="#" onClick="javascript:history.go(-1); return false">
      <div class="back" @click="back()">Услуги</div>
    </a>

    <div style="padding: 5%">
      <h1>Услуги</h1>
      <p>Доступно в текущем тарифном плане</p>
    </div>

    <div class="div" style="display: flex; gap: 10px; flex-direction: column; margin: 10% 0">
      <div @click="serviceSelected = s.ID_SERVICE_INST; selectedName = s.NAME; priceSelected = s.COST"
        v-for="s in connected" :key="s.NAME" style="display: flex; justify-content: space-between">
        <p class="active" style="
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
          ">
          {{ s.NAME + " " }} <b> {{ ' ' + s.COST }} руб. / мес</b>
        </p>
        <p @click="showModal = true;" onclick="log(this);" style="
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            text-align: right;
            text-transform: uppercase;

            color: #2da9a1;
          ">
          Отключить
        </p>

      </div>


      <div @click="serviceSelected = s.ID_SERVICE; selectedName = s.SERVICE_NAME" v-for="s in services"
        :key="s.SERVICE_NAME" style="display: flex; justify-content: space-between">
        <p style="
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
          ">

          {{ s.SERVICE_NAME }}<b style="margin-left: 5px"></b>
        </p>
        <p @click="showModal = true" onclick="log(this)" style="
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            text-align: right;
            text-transform: uppercase;

            color: #2da9a1;
          ">
          Подключить
        </p>
      </div>

    </div>
    <footer style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      ">
      <div class="left">
        <router-link to="/koran"><img src="../assets/koran-black.svg" alt="" class="footer-koran footer-icon" />
        </router-link>
        <router-link to="/imam"><img src="../assets/imam-footer.svg" alt="" class="footer-question footer-icon" />
        </router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt="" />
      </router-link>
      <div class="right">
        <router-link to="/calend"><img src="../assets/Icon3.svg" alt="" class="footer-calend footer-icon"
            @click="openCalend()" /></router-link>
        <router-link to="/blago"><img src="../assets/Icon4.svg" alt="" class="footer-blagos footer-icon" />
        </router-link>
      </div>
    </footer>
  </div>
</template>

<script>
// import Datepicker from 'vuejs-datepicker';
import Vue from "vue";
import axios from "axios";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "HelloWorld",
  data: () => ({
    priceSelected: null,
    selectedName: null,
    ok: null,
    toggleModal: null,
    serviceSelected: null,
    connected: [],
    services: [],
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
  }),
  async created() {
    let that = this
    window.log = this.log
    await axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/available_services", "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":${localStorage.getItem("sessid")}}}`
      )
      .then(function (response) {
        that.services = response.data.services;
      })
      .catch(function (error) {
        console.error(error);
        return false;
      });

    await axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/connected_services", "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":${localStorage.getItem("sessid")}}}`
      )
      .then(function (response) {
        that.connected = response.data.services;
      })
      .catch(function (error) {
        console.error(error);
        return false;
      });
  },
  methods: {
    log(el) {
      this.toggleModal = el.innerHTML
      scrollTo(0, 0)
      console.log(el.innerHTML)
    },
    back() {
      this.$emit("back", false);
    },
    changeService() {

      let data2 = `instance_id=${this.serviceSelected}`;
      if (this.toggleModal == ' Отключить ') {
        let that = this
        axios
          .post(
            "https://api.sabil.webinfra.ru/sabil",
            `{"path": "https://api.sabeel.credo.ru:5543/api/del_service", "data": ${JSON.stringify(
              data2
            )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":${localStorage.getItem("sessid")}}}`
          )
          .then(function (response) {
            console.log(response)
            that.ok = true
            location.reload()
          })
          .catch(function (error) {
            console.error(error);
            return false;
          });
      }

      if (this.toggleModal == ' Подключить ') {
        let data3 = `service_id=${this.serviceSelected}`
        let that = this
        axios
          .post(
            "https://api.sabil.webinfra.ru/sabil",
            `{"path": "https://api.sabeel.credo.ru:5543/api/add_service", "data": ${JSON.stringify(
              data3
            )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":${localStorage.getItem("sessid")}}}`
          )
          .then(function (response) {

            that.ok = true
            console.log(response)
            location.reload()
          })
          .catch(function (error) {
            console.error(error);
            return false;
          });
      }
      this.showModal = false

    }
  },
};
</script>
<style scoped lang="scss">
ul.menu {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}

ul.menu li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 15px 40px;
  text-align: left;
  position: relative;
}

.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.div {
  margin-bottom: 150px !important;
}

.div div {
  padding: 5% 5%;
  border-bottom: 1px solid #dadada;
}

.div div:first-child {
  border-top: 1px solid #dadada;
}

p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;

  color: #2da9a1;
}

.tariff {
  margin: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #000000;

  border-bottom: 0.5px solid #afafaf;
}

.tariff div {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  margin-bottom: 1%;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #39b2ba;
}

.heading {
  margin: 20% auto;
  margin-bottom: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;

  color: #2da9a1;
}

ul {
  padding: 2% 5% !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

li {
  display: flex;
  text-align: left;
  border-bottom: 1px solid #dadada;

  padding: 1%;
}

ul li div:last-child {
  text-align: center;
}

button {
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  width: 80%;
  padding: 10px;
  margin: 0 auto;
  color: #39b2ba;
}

.select {
  margin: 10%;
  padding: 30px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  position: relative;
  height: min-content;
}

select {
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-transform: capitalize;
  color: #000000;
  width: 100%;
}

.select-item {
  display: inline-flex;
  margin: 5% auto;
}

label {
  text-align: left;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: uppercase;

  color: #2da9a1;
}

ul {
  padding: 10px;
}

ul li {
  list-style: none;
  gap: 5%;
}

p.active::before {
  content: '';
  position: absolute;
  left: 5px;
  width: 20px;
  height: 20px;
  background-image: url('../assets/galka.svg');
}

.searchbar input {
  background: #ffffff;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  // position: relative;
}

.searchbar {
  position: relative;
  margin-top: 2%;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}

.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

.header-icon {
  margin-right: 10px;
}

.support-page {
  margin: 12% 0;
  padding-bottom: 20px;
  text-align: left;
  // margin: 5%;
  padding-top: 0 !important;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100%;
  background-repeat: repeat;
  position: relative;
}

header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}

.footer-icon {
  padding: 10px;
}

.subModal p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #636363;
  margin-top: 8px;
}

.subModal div {
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  padding-bottom: 5px;
}

.subModal button {
  color: #39b2ba;
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  height: 40px;
  margin: 5% auto;
  margin-bottom: 0;
}
</style>
