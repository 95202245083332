<!--TODO: eslint -->
<template>
  <div style="padding-top: 50px">
    <img src="../assets/logo.png" alt="" />
    <p class="auth-desc" style="font-size: 15px; color: #ccb282">Шаг 3 из 3</p>
    <!-- <p
      class="auth-desc"
      style="font-size: 14px; color: #39b2ba; padding: 10px 15px"
    >
      Придумайте 4-х значный цифровой пароль для быстрого входа в приложение:
    </p>

    <input v-model="myModel" maxlength="4" /> -->
    <!-- <form class="wrap">
    <input
      v-for="(i, idx) in [0, 1, 2, 3]"
      :key="idx"
      maxlength="1"
      :id="'input' + i"
      :value="data[i] || ''"
      @input="onType($event, i)"
      @keyup.delete="onDelete($event, i)"
    />
  </form> -->
    <form class="wrap">
      <input
        type="number"
        v-for="(i, idx) in [0, 1, 2, 3]"
        :key="idx"
        maxlength="1"
        :id="'input' + i"
        v-model="data[i]"
        @input="onType($event, i)"
        @keyup.delete="onDelete($event, i)"
      />
    </form>

    <button
      :disabled="!(data.join('').length == 4)"
      @click="showMain()"
      id="delete-pin"
    >
      Войти
    </button>
    <p style="margin-top: 10px" v-if="errored">{{ errored }}</p>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    data: [],
    myModel: "",
    errored: false,
  }),
  mounted() {
    if (!localStorage.getItem("localPassword")) {
      this.errored = "Придумайте 4-значный пароль";
    }
  },
  methods: {
    showMain() {
      console.log(localStorage.getItem("localPassword"));
      var md5 = require("md5");
      if (localStorage.getItem("localPassword")) {
        if (localStorage.getItem("localPassword") == md5(this.data.join(""))) {
          localStorage.setItem("sabilLogin", true);

          this.$router.push("/main");
          console.log(1);
        } else {
          this.errored = "Неверный PIN";
        }
      } else {
        localStorage.setItem("localPassword", md5(this.data.join("")));
        localStorage.setItem("sabilLogin", true);
        this.$router.push("/main");
      }
    },
    onType(e, idx) {
      this.data[idx] = e.target.value;

      if (idx < 3 && ("" + this.data[idx]).length > 0)
        document.getElementById(`input${idx + 1}`).focus();
    },
    onDelete(e, idx) {
      if (!e.target.value && idx > 0)
        document.getElementById(`input${idx - 1}`).focus();
    },
  },
};
</script>
<style scoped lang="scss">
* {
  color: #39b2ba;
}

strong {
  color: #ccb282;
}

span {
  margin-top: 60px !important;
  display: block;
}

#delete-pin {
  background: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 12px;
  margin-top: 50px;
  width: 300px;
  color: #39b2ba;
  font-weight: 400;
  font-size: 15px;
  border-radius: 7px;
}

.wrap {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 50px;
}

input {
  width: 40px;
  border: none;
  border-bottom: 1px solid #39b2ba;
  color: #39b2ba;
  font-size: 40px;
  text-align: center;
  outline: none;
}
</style>
