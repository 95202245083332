<!--TODO: eslint -->
<template>
  <div class="support-page">

    <a href="#" onClick="javascript:history.go(-1); return false">
      <div class="back" @click="back()">Благодеяния</div>
    </a>
    <div class="root">
      <p class="date">{{ new Date(article.date * 1000).getDate() + ' ' + getMonthName(new
          Date(article.date * 1000).getMonth()) + ' ' + new Date(article.date * 1000).getFullYear()
      }}</p>
      <h1>{{ article.title }}</h1>
      <div class="sub" style="display: flex; justify-content: space-between">
        <p>Регион: Москва</p>
        <!-- <p>Фото: Пресс-служба ДУМ РФ</p> -->
      </div>
      <img class="main-img" :src="article.featured_image.url" alt="" />
      <div v-html="article.html"></div>
      <div class="vote_container">
          <button v-on:click.stop.prevent="like(1)" v-if="!article.liked">Проголосовать за проект</button>
          <p v-if="article.liked" class="already_voted_message">Вы проголосовали за этот проект в этом месяце</p>
      </div>
    </div>

    <!-- <div>
      <router-link to="/voteresult">
        <button>Проголосовать за проект</button>
      </router-link>
    </div> -->

    <footer style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      ">
      <div class="left">
        <router-link to="/koran"><img src="../assets/koran-black.svg" alt=""
            class="footer-koran footer-icon" /></router-link>
        <router-link to="/imam"><img src="../assets/imam-footer.svg" alt=""
            class="footer-question footer-icon" /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt="" /></router-link>
      <div class="right">
        <router-link to="/calend"><img src="../assets/Icon3.svg" alt="" class="footer-calend footer-icon"
            @click="openCalend()" /></router-link>
        <router-link to="/blago"><img src="../assets/blago-active.svg" alt=""
            class="footer-blagos footer-icon" /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import store from "../store/index";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import {getArticle, likePage} from "@/api/api";
export default {
  name: "BlagoArticle",
  data: () => ({
    article: '',

  }),
  methods: {
    back() {
      this.$emit("back", false);
    },
    getMonthName(month) {
      const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
        "июля", "августа", "сентября", "октября", "ноября", "декабря"]
      return monthNames[month]
    },

    async like(action) {
      try {
        const idObj = {id: store.getters.getBlagoSelected}
        await likePage(idObj, action, JSON.parse(localStorage.getItem("sessid")))
        this.$router.push('voteresult')
      } catch (e) {
        console.error('e ===', e)
      }
    },
  },



  async created() {
    let that = this;
    const id = "page_id=" + store.getters.getBlagoSelected;
    if (!store.getters.getBlagoSelected) {
      this.$router.push('blago')
    }
    try {
      const response = await getArticle(id, JSON.parse(localStorage.getItem("sessid")))
      that.article = response.data;
      console.log('that.article', that.article)
    }
    catch (error) {
      console.log(error);
      return false;
    }
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}

.vote_container {
  position: fixed;
  bottom: 55px;
  width: 100%;
  height: 70px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px -25px 10px 0px rgba(255, 255, 255, 0.5);
}

ul li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
  margin: 0;
}

button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background: rgba(57, 178, 186, 0.2);
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 3% 7%;
  text-align: center;
  position: relative;
  color: #39b2ba;
}

body {
  padding-bottom: 300px !important;
}

button::before {
  position: absolute;
  content: "";
  top: 40%;
  left: 5%;
  width: 20px;
  height: 20px;
  background-image: url("../assets/check.svg");
}

.already_voted_message {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #39b2ba;
  text-align: center;

  &:before {
    position: absolute;
    content: "";
    top: 40%;
    left: 5%;
    width: 20px;
    height: 20px;
    background-image: url("../assets/check.svg");
  }
}

.root {
  padding: 5%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;

  color: #363636;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  color: #2da9a1;
}

.sub p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;

  color: #363636;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}

.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

.header-icon {
  margin-right: 10px;
}

.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  background-repeat: repeat;
  padding-bottom: 120px !important;
  position: relative;
}

header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}

.footer-icon {
  padding: 10px;
}

.root {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // margin-bottom: 30%;
}

.date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;

  color: #363636;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #2da9a1;
}

.sub p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;

  color: #363636;
}

.main-img {
  max-height: 100%;
  //height: 200px;
  object-fit: contain;
}
</style>
