import Vue from "vue";
import axios from "axios";
import VueRouter from "vue-router";
import AuthStep2 from "../components/AuthStep2.vue";
import AuthStep3 from "../components/AuthStep3.vue";
import SupportNav from "../components/SupportNav.vue";
import SupportNav2 from "../components/SupportNav2.vue";
import HomePage from "../components/HomePage.vue";
import MainPage from "../pages/main-page/MainPage.vue";
import NewsComponent from "../components/NewsComponent.vue";
import AskQuestion from "../components/AskQuestion.vue";
import AuthStep1 from "../components/AuthStep1.vue";
import VoteResult from "../components/VoteResult.vue";
// import ProfileNav from '../components/ProfileNav.vue'
import ProfileSett from "../components/ProfileSett.vue";
import Calendar from "../components/CalendarComponent.vue";
import MyTariff from "../components/MyTariff.vue";
import BlagoComp from "../pages/blago-page/BlagoPage.vue";
import ImamComponent from "../components/ImamComponent.vue";
import KoranPage from "../components/KoranPage.vue";
import BlagoArticle from "../components/BlagoArticle.vue";
import NotsComponent from "../components/NotsComponent.vue";
import VoteStats from "../components/VoteStats.vue";
import TranzPage from "../components/TranzPage.vue";
import TranzFilter from "../components/TranzFilter.vue";
import MyConnection from "../components/MyConnection.vue";
import UslugiPage from "../components/UtilitiesPage.vue";
import ChangeTariff from "../components/ChangeTariff.vue";
import ChooseCity from "../components/ChooseCity.vue";
import KoranFull from "../components/KoranFull.vue";
import NewsFull from "../pages/article-page/NewsFull.vue";
import AyatFull from "../components/AyatFull.vue";
import AboutPage from "../components/AboutPage.vue";
import RulesPage from "../components/RulesPage.vue";
import RuleFull from "../components/RuleFull.vue";
import ChooseNotis from "../components/ChooseNotis.vue";
import ConfidPage from "../components/ConfidPage.vue";
import store from "../store/index.js";
import PDFPreview from "@/components/PDFPreview.vue";
import { isLogin } from "@/utils/local-storage.utils";
import ChangePassword from "@/pages/change-password/ChangePassword.vue";

Vue.use(VueRouter);
const cookieJar = {
  myCookies: null,
};

const checkLogin = (cb) => {
  if (isLogin()) {
    cb();
  } else {
    return false;
  }
};

const routes = [
  {
    path: "/confid",
    component: ConfidPage,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/choosenotis",
    component: ChooseNotis,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/rulefull",
    component: RuleFull,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/rules",
    component: RulesPage,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/about",
    component: AboutPage,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/fullkoran",
    component: KoranFull,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/city",
    component: ChooseCity,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/main",
    component: MainPage,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/changetariff",
    component: ChangeTariff,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/uslugi",
    component: UslugiPage,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/myconn",
    component: MyConnection,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/tranzfilter",
    component: TranzFilter,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/tranz",
    component: TranzPage,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/stats",
    component: VoteStats,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/nots",
    component: NotsComponent,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/change_password",
    component: ChangePassword,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/auth1",
    name: "auth1",
    component: AuthStep1,
    beforeEnter: (to, from, next) => {
      if (
        localStorage.getItem("sabilLogin") &&
        JSON.parse(localStorage.getItem("usePassword")) == false
      ) {
        next("/main");
      } else if (localStorage.getItem("sabilLogin")) {
        next("/auth3");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth2",
    name: "auth2",
    component: AuthStep2,
    beforeEnter: (to, from, next) => {
      let tel = document
        .querySelector(".tel")
        .value.replace(/[^A-Za-z0-9]/g, "");
      console.log(tel);
      var data = "msisdn=" + tel;
      axios
        .post(
          "https://api.sabil.webinfra.ru/sabil",
          `{"path": "https://api.sabeel.credo.ru:5543/api/auth", "data": ${JSON.stringify(
            data
          )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response) {
          // cookieJar.myCookies = response.headers['Content-Type'];
          // console.log(cookieJar.myCookies)
          console.log(JSON.stringify(response.data.ok));
          console.log("response auth2 ===", response);
          if (JSON.stringify(response.data.ok)) {
            console.log(JSON.stringify(response.data.PHPSESSID));
            cookieJar.myCookies = JSON.stringify(response.data.PHPSESSID);
            store.commit("SET_PIN", JSON.parse(cookieJar.myCookies));
            localStorage.setItem("sessid", cookieJar.myCookies);
            next();
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
    },
  },
  {
    path: "/auth3",
    name: "auth3",
    component: AuthStep3,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("sabilLogin")) {
        let tel2 =
          document.getElementsByTagName("input")[0].value +
          document.getElementsByTagName("input")[1].value +
          document.getElementsByTagName("input")[2].value +
          document.getElementsByTagName("input")[3].value;
        console.log(tel2);
        var data2 = "token=" + tel2;
        axios
          .post(
            "https://api.sabil.webinfra.ru/sabil",
            `{"path": "https://api.sabeel.credo.ru:5543/api/auth", "data": ${JSON.stringify(
              data2
            )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID": ${
              cookieJar.myCookies
            }}}`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            if (JSON.stringify(response.data.ok)) {
              localStorage.setItem(
                "tariff",
                JSON.stringify(response.data.services)
              );
              store.commit("SET_PIN", JSON.parse(cookieJar.myCookies));
              document.cookie = `"PHPSESSID"=${cookieJar.myCookies}`;
              next();
            } else {
              return false;
            }
          })
          .catch(function (error) {
            console.log(error);
            return false;
          });
      } else {
        next();
      }
    },
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
    beforeEnter: (to, from, next) => {
      console.log("store.getters.getPin11111====", store.getters.getPin);
      if (store.getters.getPin === false) {
        return false;
      } else {
        next();
      }
    },
  },
  {
    path: "/supportnav",
    component: SupportNav,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/supportnav2",
    component: SupportNav2,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/ask",
    component: AskQuestion,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/profilenav",
    component: ProfileSett,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },

  {
    path: "/profilesett",
    component: ProfileSett,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/koran",
    component: KoranPage,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/ayat",
    component: AyatFull,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/news",
    component: NewsComponent,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/newsfull",
    component: NewsFull,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/imam",
    component: ImamComponent,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/calend",
    component: Calendar,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/blago",
    component: BlagoComp,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/tariff",
    component: MyTariff,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/blagofull",
    component: BlagoArticle,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    path: "/voteresult",
    component: VoteResult,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  {
    name: "PREVIEW",
    path: "/preview",
    component: PDFPreview,
    beforeEnter: (to, from, next) => {
      checkLogin(next);
    },
  },
  // {
  //   path: '/conf',
  //   component: ConfComp
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
