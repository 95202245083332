<template>
  <div class="support-page">
    <a href="#" onClick="javascript:history.go(-1); return false">
      <div class="back" @click="back()">{{pdfName}}</div>
    </a>

    <div>
      <pdf :src="pdfLink"></pdf>
    </div>
    <footer
        style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
        ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
        ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
        /></router-link>
      <div class="right">
        <router-link to="/calend"
        ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
        ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import pdf from 'vue-pdf'

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  data() {
    return {
      pdfName: '',
      pdfLink: ''
    }
  },

  methods: {
    back() {
      this.$emit("back", false);
    },
  },

  mounted(){
    window.scrollTo(0, 0);

    this.pdfName = this.$route.params.pdfName
    this.pdfLink = this.$route.params.pdfLink
  },

  components: {
    pdf,
  }
};
</script>

<style scoped lang="scss">
.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}
.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 200vh;
  background-repeat: repeat;
  position: relative;
}
footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
button {
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 12px;
  width: 10em;
  color: #39b2ba;
  font-size: 15px;
  border-radius: 7px;
}
</style>
