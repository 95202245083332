<!--TODO: eslint -->
<template>
  <div class="support-page">
    <a href="#" onClick="javascript:history.go(-1); return false">
      <div class="back" @click="back()">Коран</div>
    </a>
    <div
      style="z-index: 1000; background-color: #fff"
      v-if="showSelect"
      class="select"
    >
      <img
        @click="showSelect = false"
        src="../assets/X.svg"
        width="10"
        style="position: absolute; right: 20px"
        alt=""
      />
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <div class="select-item">
        <img src="../assets/reader.svg" alt="" />
        <label for=""
          >Выбор чтеца
          <select name="" id="">
            <option value="lorem">lorem</option>
          </select>
        </label>
      </div>
      <button>Сохранить</button>
    </div>

    <tabs :mode="mode">
      <tab title="Содержание">
        <router-link to="/fullkoran">
          <div v-if="!$store.state.arrayList" class="loading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

          <ul v-else>
            <li
              @click="callStore(q.surah)"
              v-for="q in $store.state.arrayList"
              :key="q.text"
            >
              <p>
                {{ q.surah }}. {{ shorter(q.text) }}
                <span
                  style="font-size: 13px; line-height: 13px; color: #2da9a1"
                ></span>
              </p>
            </li>
          </ul>
        </router-link>
      </tab>
      <tab title="Избранное">
        <ul>
          <li v-for="ayat in koranFavArray" :key="ayat[0].text">
            <div
              class="fav_ayat"
              @click="openAyat(ayat[0].surah, ayat[0].stih)"
            >
              <!--              <div style="width: 250px; text-align: right">-->
              <!--                <h1 dir="rtl" translate="no">{{ ayat[2].text }}</h1>-->
              <!--              </div>-->

              <!--              <span style="font-size: 15px; line-height: 20px; color: black">{{-->
              <!--                ayat[1].text-->
              <!--              }}</span>-->
              <div>
                <span>{{
                  `${ayat[3].text} ${ayat[3].surah}:${ayat[0].stih}`
                }}</span>
              </div>
              <div>
                <span>{{ ayat[1].text }}</span>
              </div>
            </div>
          </li>
        </ul>
      </tab>
    </tabs>

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-active.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
// import axios from "axios"
import Vue from "vue";
import vSelect from "vue-select";
import axios from "axios";
import Tabs from "./NewsTabs.vue";
import Tab from "./NewsTab.vue";
import store from "../store/index.js";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  components: { Tabs, Tab },
  name: "KoranPage",
  data: () => ({
    fav: [],
    quran: [],
    koranFavArray: [],
    variable: null,
    activeTab: "",
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
    showSelect: false,
  }),
  methods: {
    setter(f) {
      localStorage.setItem("ayat", f.ayah);
      localStorage.setItem("selected", f.surah - 1);
      this.$router.push("/ayat");
    },
    callStore(x) {
      store.commit("SET_SURAH", x);
      localStorage.setItem("selected", x - 1);
    },
    openAyat(s, st) {
      this.callStore(s);
      store.commit("SET_AYAT", {
        surah: s,
        stih: st,
      });
      localStorage.setItem("ayat", st);
      this.$router.push("/ayat");
    },
    back() {
      this.$emit("back", false);
    },
    shorter(s) {
      if (typeof s !== "string") {
        return "";
      }
      if (s.length > 24) {
        let str = s.slice(0, 25);
        return `${str}...`;
      } else {
        return s;
      }
    },
  },
  mounted() {
    this.$store.commit("SET_ARRAYLIST");
    this.$store.commit("SET_NEW");
  },
  async created() {
    let that = this;

    await axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/fav_ayah_list", "data": "folder_id=0", "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":  ${localStorage.getItem(
          "sessid"
        )}}}`
      )
      .then(function (response) {
        const favList = response.data.fav_list ?? [];
        const fullKoran = that.$store.getters.getNewArray.map(
          ({ data }) => data.quran
        );
        // translation  === 0 - symbols text
        // translation  === 2 - originals text
        // translation  === 3 - rus text
        that.koranFavArray = favList
          .map(({ surah, ayah }) => {
            const fullSurah = fullKoran[surah - 1];
            const favAyahArray = fullSurah.filter(
              ({ stih }) => stih === String(ayah)
            );
            if (favAyahArray.length === 3) {
              return favAyahArray;
            }
          })
          .filter((data) => data)
          .map((ayatArray) => {
            return [
              ...ayatArray,
              that.$store.state.arrayList.find(
                ({ surah }) => surah === ayatArray[0].surah
              ),
            ];
          });
        // console.log('that.koranFavArray', that.koranFavArray, fullKoran, that.$store.state.arrayList)
        // console.log('that.koranFavArray', that.koranFavArray)
      })
      .catch(function (error) {
        console.error(error);
        return false;
      });

    let result = this.fav;
    localStorage.setItem("fav", JSON.stringify(result));
  },
};
</script>
<style scoped lang="scss">
.favli {
  justify-content: flex-start !important;
}

ul {
  padding-bottom: 160px !important;
}

ul.menu {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}

ul.menu li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}

img {
  justify-self: flex-end;
}

.fav_ayat {
  display: flex;
  flex-direction: column;
  & > div:first-child {
    display: flex;
    justify-content: flex-start;
    & > span {
      text-align: left;
    }
  }
  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    & > span {
      text-align: right;
    }
  }
  & > div > span {
    width: 70%;
    display: block;
    list-style: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: black;
    text-align: left;
  }
}

button {
  background: rgba(57, 178, 186, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  width: 80%;
  padding: 10px;
  margin: 0 auto;
  color: #39b2ba;
}

.select {
  margin: 30% 10%;
  //  margin-top: 5%;
  position: absolute;
  padding: 30px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  position: relative;
  height: min-content;
}

select {
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-transform: capitalize;
  color: #000000;
  width: 100%;
}

.select-item {
  display: inline-flex;
  margin: 5% auto;
}

label {
  text-align: left;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: uppercase;

  color: #2da9a1;
}

ul {
  padding: 10px;
  // padding-bottom: 60px;
}

ul li {
  display: flex;
  padding: 2% 0;
  border-bottom: 1px solid #dadada;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
}

ul li p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #000000;
}

.searchbar input {
  background: #ffffff;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 70%;
  padding: 3px;
  font-weight: 400;
  font-size: 12px;
  padding: 3%;
  line-height: 15px;
  color: #a8a8a8;
  // position: relative;
}

.searchbar {
  position: relative;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}

.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.select {
  z-index: 1000;
}

.user {
  display: flex;
  align-items: flex-end;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

.header-icon {
  margin-right: 10px;
}

.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}

header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}

.footer-icon {
  padding: 10px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2da9a1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
</style>
