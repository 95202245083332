<!--TODO: eslint -->
<template>
  <div class="support-page">


    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Выбор региона</div></a
    >
    <router-link to="/main"
      ><ul>
        <li @click="$store.commit('SET_CITY', propertyName
        ); $store.commit('SET_CITY_NAME', value
        )" v-for="(value, propertyName) in cityData" :key="propertyName">{{value}}</li>
        <!-- <li>Санкт-Петербург</li>
        <li>Санкт-Петербург</li>
        <li>Санкт-Петербург</li>
        <li>Санкт-Петербург</li>
        <li>Санкт-Петербург</li>
        <li>Санкт-Петербург</li>
        <li>Санкт-Петербург</li>
        <li>Санкт-Петербург</li>
        <li>Санкт-Петербург</li> -->
      </ul></router-link
    >

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
// import store from "../store/index";
import vSelect from "vue-select";
import axios from "axios";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "HelloWorld",
  data: () => ({
    cityData:[],
 
   
  }),
  methods: {
    getUserIconPath() {
      return this.userIconPath;
    },
    back() {
      this.$emit("back", false);
    },
  },
     created() {
    let that = this;
     axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/prayer_cities", "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response) {
        // cookieJar.myCookies = response.headers['Content-Type'];
        // console.log(cookieJar.myCookies)
        that.cityData=JSON.parse(JSON.stringify(response.data.cities));
        for(let c in that.cityData) {
        if(that.cityData[c] == "Москва"||that.cityData[c] == "Томск"||that.cityData[c] == "Санкт-Петербург") {
            delete that.cityData[c];
        }}
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  },
};
</script>
<style scoped lang="scss">
.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}



.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}



footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
ul {
  text-align: left;
  padding: 5%;

  list-style: none;
}

ul li {
  border-bottom: 1px solid #dadada;
  padding: 5%;
  padding-left: 0;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
ul li:first-child {
  padding-top: 0;
}

ul.menu {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}
ul.menu li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}
</style>
