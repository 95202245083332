<!--TODO: eslint -->
<template>
  <div class="support-page">
 

    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Настройка уведомлений</div></a
    >
    <!-- <router-link to="/main"
      ><ul>
        <li>
          <img src="../assets/switchoff.svg" alt="" /> Внесение абонентской
          платы
        </li>
        <li>
          <img src="../assets/Switch.svg" alt="" /> Внесение абонентской платы
        </li>
        <li>
          <img src="../assets/Switch.svg" alt="" /> Внесение абонентской платы
        </li>
        <li>
          <img src="../assets/switchoff.svg" alt="" /> Внесение абонентской
          платы
        </li>
        <li>
          <img src="../assets/Switch.svg" alt="" /> Внесение абонентской платы
        </li>
        <li>
          <img src="../assets/Switch.svg" alt="" /> Внесение абонентской платы
        </li>
        <li>
          <img src="../assets/switchoff.svg" alt="" /> Внесение абонентской
          платы
        </li>
        <li>
          <img src="../assets/Switch.svg" alt="" /> Внесение абонентской платы
        </li>
      </ul></router-link
    > -->
Функционал в разработке
    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "HelloWorld",
  data: () => ({
    showMenu: false,
    showModal: false,
    showShare: false,
    showNots: false,
  }),
  methods: {
    back() {
      this.$emit("back", false);
    },
  },
};
</script>
<style scoped lang="scss">
.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}
.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}
.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}
.header-icon {
  margin-right: 10px;
}
.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}
header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}
.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}
.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
ul {
  text-align: left;
  padding: 5%;

  list-style: none;
}

ul li {
  border-bottom: 1px solid #dadada;
  padding: 5%;
  padding-left: 0;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
ul li:first-child {
  padding-top: 0;
}

ul.menu {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}
ul.menu li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
}
</style>
