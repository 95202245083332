<!--TODO: eslint -->
<template>
  <div class="suport-page">
    <!-- <div @click="showMenu=false; showMenu2=false" v-if="showMenu" style="position:absolute;display:inline;top:-30%; left:0;width:100%; height:150vh;opacity:0.3;background-color:black; z-index: 1000"></div> -->
    <ul
      v-if="showMenu"
      style="
        z-index: 1000;
        position: fixed;
        width: 70%;
        right: 0;
        bottom: 0;
        top: 0;
        background: white;
      "
    >
      <li><router-link to="">Избранное</router-link></li>
      <li><router-link to="">Актуальное</router-link></li>
      <li><router-link to="">События </router-link></li>
      <li>Мультимедиа</li>
    </ul>

    <div
      v-if="showNots"
      style="
        position: fixed;
        margin-top: 20%;
        z-index: 1000;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        top: 0;
        text-align: left;
        right: 0;
        bottom: 5%;
        height: min-content;
        width: 80%;
        background-color: white;
        padding: 8% 5%;
      "
    >
      <img
        @click="showNots = false"
        style="position: absolute; right: 5%"
        src="../assets/X.svg"
        alt=""
      />

      <p
        style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;

          color: #000000;
          margin-bottom: 5%;
        "
      >
        Последние непрочитанные уведомления
      </p>
      <div
        style="
          display: flex;
          border-bottom: 1px solid #dadada;
          padding: 5% 0;
          gap: 1%;
          z-index: 1000;
        "
      >
        <div>
          <p
            style="
              color: #2da9a1;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
            "
          >
            Вопрос имаму
          </p>
          <p
            style="
              font-weight: 400;
              font-size: 11px;
              line-height: 13px;

              color: #000000;
            "
          >
            Получен ответ на вопрос имаму
          </p>
        </div>

        <p
          style="
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            color: #363636;
          "
        >
          15:30 - 02.03.2022
        </p>
      </div>
      <div
        style="
          display: flex;
          border-bottom: 1px solid #dadada;
          padding: 5% 0;
          gap: 1%;
          z-index: 1000;
        "
      >
        <div>
          <p
            style="
              color: #2da9a1;
              font-weight: 700;
              font-size: 9px;
              line-height: 11px;
            "
          >
            Вопрос имаму
          </p>
          <p
            style="
              font-weight: 400;
              font-size: 11px;
              line-height: 13px;

              color: #000000;
            "
          >
            Получен ответ на вопрос имаму
          </p>
        </div>

        <p
          style="
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            color: #363636;
          "
        >
          15:30 - 02.03.2022
        </p>
      </div>
      <router-link to="/nots">
        <p
          style="
            color: #39b2ba;
            text-align: right;
            font-size: 9px;
            line-height: 11px;
            margin-top: 3%;
          "
        >
          Посмотреть все уведомления
        </p>
      </router-link>
    </div>

    <div
      @click="
        showMenu = false;
        showMenu2 = false;
      "
      v-if="showModal || showMenu || showNots || showShare || showMenu2"
      style="
        z-index: 999;
        position: absolute;
        display: inline;
        top: -20%;
        left: 0;
        width: 100%;
        height: 120vh;
        opacity: 0.3;
        background-color: black;
        overflow-y: hidden;
      "
    ></div>
    <ul
      v-if="showMenu2"
      style="
        z-index: 10000;
        position: fixed;
        width: 70%;
        right: 0;
        bottom: 0;
        top: 0;
        background: white;
      "
    >
      <li><router-link to="/news">Новости</router-link></li>
      <li><router-link to="/tranz">Транзакции</router-link></li>
      <li><router-link to="/myconn">Моя связь</router-link></li>

      <li><router-link to="/supportnav">Техподдержка</router-link></li>
    </ul>
    <a href="#" onClick="javascript:history.go(-1); return false">
      <div class="back" @click="back()">Новости</div>
    </a>
    <!-- <div style="position: relative" class="searchbar">
      <input placeholder="Поиск" type="text" />
      <img
        style="position: absolute; top: 20%; right: 20%"
        src="../assets/loopa.svg"
        alt=""
      />
      <img
        @click="showMenu = true"
        style="margin-left: 10px"
        src="../assets/morearrow.svg"
        alt=""
      />
    </div> -->

    <tabs :mode="mode">
      <!-- <div style="padding: 0 5%">
        <p
          style="
            font-weight: 500;
            font-size: 9px;
            line-height: 11px;
            padding: 1%;
            text-align: center;
            color: #a8a8a8;
            border-bottom: 0.1px solid #a8a8a8;
          "
        >
          Сортировать
        </p>
        <div style="display: flex; justify-content: center; width: 100%">
          <div style="text-align: center">
            <p class="sort-item">Источник</p>
          </div>
          <div>
            <p class="sort-item">Регион</p>
          </div>
          <div>
            <p class="sort-item">Дата</p>
          </div>
        </div>
      </div> -->
      <tab title="Актуальное">
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;
          "
        >
          <!-- <ArticleComponent/> -->
          <!-- <ArticleComponent/> -->
          <div v-for="d in newsCards" :key="d.id" class="news-card">
            <div
              @click="
                callStore(d.id);
                $router.push('/newsfull');
              "
            >
              <img :src="d.featured_image.url" alt="" />
              <div class="news-items">
                <!--                <p class="nc-text">{{ shorter(d.title) }}</p>-->
                <p class="nc-text">{{ d.title }}</p>
                <p class="nc-date">
                  {{
                    new Date(d.modified).getDate() +
                    " " +
                    getMonthName(new Date(d.modified).getMonth()) +
                    " " +
                    new Date(d.modified).getFullYear()
                  }}
                </p>
              </div>
            </div>
            <div
              @click="
                clicked.push(d);
                like(d, 1);
              "
              v-if="!clicked.includes(d)"
              class="like-btn-active"
            ></div>
            <div @click="like(d, 0)" v-else class="like-btn"></div>
          </div>
        </div>

        <!-- <ArticleComponent/> -->
      </tab>
      <tab title="Избранное">
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;
          "
        >
          <!-- <ArticleComponent/> -->
          <!-- <ArticleComponent/> -->
          <div
            v-for="(d, index) in clicked"
            :key="d.id"
            :class="['news-card', { active: index === 0 }]"
          >
            <div
              @click="
                callStore(d.id);
                $router.push('/newsfull');
              "
            >
              <img :src="d.featured_image.url" alt="" />
              <div class="news-items">
                <!--                <p class="nc-text">{{ shorter(d.title) }}</p>-->
                <p class="nc-text">{{ d.title }}</p>
                <p class="nc-date">
                  {{
                    new Date(d.modified).getDate() +
                    " " +
                    getMonthName(new Date(d.modified).getMonth()) +
                    " " +
                    new Date(d.modified).getFullYear()
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </tab>

      <tab title="Мультимедиа">Мы готовим для вас интересный контент</tab>
    </tabs>

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/Icon4.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import axios from "axios";
import Tabs from "./NewsTabs.vue";
import Tab from "./NewsTab.vue";
import store from "../store/index.js";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import { getFavPages, likePage } from "@/api/api";
// import ArticleComponent from "./ArticleComponent.vue";
export default {
  components: { Tabs, Tab },
  name: "NewsComponent",
  data: () => ({
    fav: [],
    clicked: [],
    showMenu: false,
    showModal: false,
    showShare: false,
    newsCards: {},
    showNots: false,
    showMenu2: false,
  }),
  methods: {
    async like(d, action) {
      let pin = JSON.parse(localStorage.getItem("sessid"));
      await likePage(d, action, pin);
      const response = await getFavPages(pin);
      this.fav = response.data.fav_list;

      let result = this.fav;

      this.fav = result
        .map((one) => this.newsCards.filter((two) => one.page_id == two.id)[0])
        .filter((e) => e != undefined);
      this.clicked = this.fav;
      localStorage.setItem("fav", JSON.stringify(result));
    },
    getMonthName(month) {
      const monthNames = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      return monthNames[month];
    },
    back() {
      this.$emit("back", false);
    },
    callStore(x) {
      store.commit("SET_POST", x);
    },
    shorter(s) {
      if (s.length > 10) {
        let str = s.slice(0, 12);
        return `${str}...`;
      } else {
        return s;
      }
    },
  },
  computed: {
    // shorter(s){
    //   let str = s.slice(0,10)
    //   return `${str}...`
    // }
  },
  async created() {
    let that = this;
    const data3 = "category_id=" + 6;
    await axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/pages", "data": ${JSON.stringify(
          data3
        )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID": "${
          this.$store.getters.getPin
        }"}}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response) {
        // cookieJar.myCookies = response.headers['Content-Type'];
        // console.log(cookieJar.myCookies)
        const pages = response.data.pages;
        that.newsCards = pages.sort(
          (a, b) => new Date(a).getTime() > new Date(b).getTime()
        );
        const MAX_NEWS_SIZE = 30;
        that.newsCards.length = Math.min(pages.length, MAX_NEWS_SIZE);
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
    await axios
      .post(
        "https://api.sabil.webinfra.ru/sabil",
        `{"path": "https://api.sabeel.credo.ru:5543/api/fav_page_list", "data": "folder_id=0", "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID":  ${localStorage.getItem(
          "sessid"
        )}}}`
      )
      .then(function (response) {
        that.fav = response.data.fav_list;
      })
      .catch(function (error) {
        console.error(error);
        return false;
      });

    let result = this.fav;

    this.fav = result
      .map((one) => this.newsCards.filter((two) => one.page_id == two.id)[0])
      .filter((e) => e != undefined);
    this.clicked = this.fav;
    localStorage.setItem("fav", JSON.stringify(result));
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style: none;
  padding: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: black;
  text-align: left;
}

ul li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
  font-size: 12px !important;
}

.searchbar input {
  background: #ffffff;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 70%;
  padding: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a8a8a8;
  // position: relative;
}

.searchbar {
  position: relative;
  margin-top: 2%;
}

.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 40px;
  text-align: left;
  position: relative;
}

.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

.header-icon {
  margin-right: 10px;
}

.suport-page {
  margin-top: 12%;
  padding-bottom: 100px;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 200vh;
  background-repeat: repeat;
  position: relative;
}

header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}

.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}

.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}

.footer-icon {
  padding: 10px;
}

.sort-item {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #39b2ba;
  border: 0.5px solid #39b2ba;
  border-radius: 3px;
  padding: 5%;
  width: 80px;
  margin: 10px 2px;
  display: block;
  text-align: center;
}

li {
  border-bottom: 0.5px solid #afafaf;
  padding: 3%;
  font-size: 12px !important;
}

.news-items {
  // height: 20%;
  padding: 15%;
  padding-left: 5%;
  padding-bottom: 5%;
  padding-top: 5%;
}

.news-card {
  width: 46%;
  //height: 90%;
  // height: 120px;
  flex: 0 0 auto;
  text-align: left;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.news::-webkit-scrollbar,
.namaz-timeline ul::-webkit-scrollbar {
  display: none;
}

.news-card img {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  //height: 130px;
  object-fit: cover;
}

.news-card:not(.active) img {
  height: 130px;
}

.nc-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #2da9a1;
  //width: 90%;
}

.nc-date {
  font-weight: 400;
  font-size: 10px;

  line-height: 12px;
  color: #363636;
}

.like-btn {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 5%;
  bottom: 5%;
  background-image: url("../assets/like.svg");
}

.like-btn-active {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 5%;
  bottom: 5%;
  background-image: url("../assets/simple-like.svg");
}

.active {
  width: 100%;
}

.active img {
  object-fit: cover;
}
</style>
