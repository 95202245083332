<template>
  <div class="support-page">
    <a href="#" onClick="javascript:history.go(-1); return false"
      ><div class="back" @click="back()">Смена пароля</div>
    </a>
    <p class="title">{{ passwordTitle }}</p>
    <form class="wrap">
      <input
        type="number"
        v-for="(i, idx) in [0, 1, 2, 3]"
        :key="idx"
        maxlength="1"
        :id="'input' + i"
        :value="data[i] || ''"
        @input="onType($event, i)"
        @keyup.delete="onDelete($event, i)"
      />
    </form>
    <div class="button_container">
      <button class="button" @click="removeAllPassword()">
        Удалить пароль
      </button>
      <button @click="goToNewPassword()" class="button action">
        {{ actionTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import md5 from "md5";

export default {
  components: {},
  name: "ChangePassword",
  data: () => ({
    data: [],
    oldPassword: "",
    newPasswordFirstTry: "",
    newPasswordSecondTry: "",
    passwordTitle: "Введите Ваш текущий пароль",
    actionTitle: "Далее",
  }),
  methods: {
    onType(e, idx) {
      this.data[idx] = e.target.value;
      if (idx < 3 && ("" + this.data[idx]).length > 0)
        document.getElementById(`input${idx + 1}`).focus();
    },
    onDelete(e, idx) {
      if (!e.target.value && idx > 0)
        document.getElementById(`input${idx - 1}`).focus();
    },
    removeAllPassword() {
      this.data = [];
      document.getElementById(`input${0}`).focus();
    },
    goToNewPassword() {
      if (this.data.length === 4 && !this.oldPassword) {
        const md5oldPassword = localStorage.getItem("localPassword");
        if (md5oldPassword === md5(this.data.join(""))) {
          this.oldPassword = this.data.join("");
          this.data = [];
          this.passwordTitle = "Введите новый пароль";
        }
      }
      if (
        this.data.length === 4 &&
        this.oldPassword &&
        !this.newPasswordFirstTry
      ) {
        this.newPasswordFirstTry = this.data.join("");
        this.data = [];
        this.actionTitle = "Сохранить";
        this.passwordTitle = "Повторите ввод нового пароля";
      }
      if (
        this.data.length === 4 &&
        this.oldPassword &&
        this.newPasswordFirstTry
      ) {
        this.newPasswordSecondTry = this.data.join("");
        if (this.newPasswordFirstTry === this.newPasswordSecondTry) {
          localStorage.setItem("localPassword", md5(this.data.join("")));
          this.$router.push("/main");
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "change-password.styles";
</style>
