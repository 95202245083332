<!--TODO: eslint -->
<template>
  <div class="support-page">
    <a href="#" onClick="javascript:history.go(-1); return false"
      >
      <div class="back" @click="back()">Статистика голосования</div>
      </a
    >
    Функционал в разрработке
    <!-- <img src="../assets/vote1.svg" alt="" />

    <img src="../assets/vote2.svg" alt="" />

    <img src="../assets/Graf1.jpg" alt="" /> -->
    <!-- <button onClick="javascript:history.go(-1); return false">Вернуться</button> -->

    <footer
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 10px 25px;
      "
    >
      <div class="left">
        <router-link to="/koran"
          ><img
            src="../assets/koran-black.svg"
            alt=""
            class="footer-koran footer-icon"
        /></router-link>
        <router-link to="/imam"
          ><img
            src="../assets/imam-footer.svg"
            alt=""
            class="footer-question footer-icon"
        /></router-link>
      </div>
      <router-link to="/main">
        <img src="../assets/menu-logo.svg" width="40" height="40" alt=""
      /></router-link>
      <div class="right">
        <router-link to="/calend"
          ><img
            src="../assets/Icon3.svg"
            alt=""
            class="footer-calend footer-icon"
            @click="openCalend()"
        /></router-link>
        <router-link to="/blago"
          ><img
            src="../assets/blago-active.svg"
            alt=""
            class="footer-blagos footer-icon"
        /></router-link>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  methods: {
    back() {
      this.$emit("back", false);
    },
  },
};
</script>
<style scoped lang="scss">
button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #39b2ba;
  background: rgba(57, 178, 186, 0.2);
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 3% 15%;
}
div.cont {
  padding: 3%;
  gap: 10px;
}
h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;

  color: #2da9a1;
}
p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  text-align: center;

  color: #000000;
}
.back {
  background-color: #f9f9f9;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding: 15px 40px;
  text-align: left;
  position: relative;
}
.back::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../assets/back-arrow.svg");
  left: 20px;
}

.user {
  display: flex;
  align-items: flex-end;
}
.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}
.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}
.header-icon {
  margin-right: 10px;
}
.support-page {
  margin-top: 12%;
  background: white;
  // background-image: linear-gradient(rgba(57, 178, 186, 0.68), rgba(57, 178, 186, 0.68)), url("../assets/Pattern.png");
  height: 100vh;
  background-repeat: repeat;
  position: relative;
}
header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  top: 0;
}
.header-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  z-index: -1;
  background-image: url("../assets/Pattern.png");
  content: "";
}
.user,
.controls {
  width: min-content;
  margin: 0;
}

footer {
  position: fixed;
  height: 55px;
  bottom: 0;
  width: 100%;
  left: 0;
  //  background-image: url('../assets/menu-logo.svg');
  background-position: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
}
.footer-icon {
  padding: 10px;
}
ul {
  text-align: left;
  margin: 15px;
  list-style: none;
}
ul li {
  display: flex;
  padding: 17px 0;
  align-items: center;
  border-bottom: 1px solid #dadada;
}
ul li:first-child {
  padding-top: 0;
}
ul li img {
  padding: 0 10px;
}
</style>
