import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { get, set } from "../utils/indexed-db";
import array from './arrayList'
import newArray from './newArray'
// import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
//   plugins: [createPersistedState({
//     storage: window.sessionStorage,
// })],

  state: {
    arrayList: [],
    newArray: [],
    citySelected: 2,
    citySelectedName: "Москва Ханафи",
    quran: null,
    numberOk: false,
    ayatSelected: {
      stih: "1",
      surah: "3",
    },
    tokenOk: true,
    blagoSelected: "",
    pin: null,
    logined: true,
    postSelected: 167,
    surahSelected: 1,
  },
  getters: {
    getLogined: (state) => {
      return state.logined;
    },
    getArrayList: (state) => {
      console.log(state.arrayList, 1241241324);
      return state.arrayList;
    },
    getNewArray: (state) => {
      return state.newArray;
    },
    getAyat: (state) => {
      return state.ayatSelected;
    },
    getQuran: (state) => {
      return state.quran;
    },
    getSurahSelected: (state) => {
      return state.surahSelected;
    },
    getCitySelected: (state) => {
      return state.citySelected;
    },
    getCitySelectedName: (state) => {
      return state.citySelectedName;
    },
    getBlagoSelected: (state) => {
      return state.blagoSelected;
    },
    getPin: (state) => {
      return state.pin;
    },
    getNumberOk: (state) => {
      return state.numberOk;
    },
    getPostSelected: (state) => {
      return state.postSelected;
    },
  },
  mutations: {
    SET_CITY(state, citySelected) {
      state.citySelected = citySelected;
    },
    SET_CITY_NAME(state, citySelectedName) {
      state.citySelectedName = citySelectedName;
    },
    SET_LOGINED(state, logined) {
      state.logined = logined;
    },
    SET_PIN(state, logined) {
      state.pin = logined;
    },
    SET_NUMBER(state, numberOk) {
      state.numberOk = numberOk;
    },
    SET_POST(state, postSelected) {
      state.postSelected = postSelected;
    },
    SET_BLAGO(state, blagoSelected) {
      state.blagoSelected = blagoSelected;
    },
    SET_SURAH(state, surahSelected) {
      
      state.surahSelected = surahSelected;
      // let url = new URL(window.location.href);
      // let params = new URLSearchParams(url.search);
      // console.log(params)
      // console.log
      // //Add a second foo parameter.
      // params.append('selected', surahSelected);
    },
    SET_KORAN(state, quran) {
      state.quran = quran;
    },
    SET_AYAT(state, ayatSelected) {
      state.ayatSelected = ayatSelected;
    },
    SET_ARRAY(state, arrayList) {
      state.arrayList.push(arrayList);
    },
    SET_ARRAYLIST(state){
      state.arrayList = array
    },
    SET_NEW(state) {
      state.newArray = newArray;
    },
  },
  actions: {
    async updateKoran({ commit }) {
      // const params = new URLSearchParams();
      // params.append('firstName', 'paul');
      await axios
        .post(
          "https://api.sabil.webinfra.ru/sabil",
          `{"path": "https://api.sabeel.credo.ru:5543/api/quran", "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID": "null"}}`
        )
        .then((res) => {
          console.log("QURAN OK");
          let newExpense = JSON.parse(JSON.stringify(res.data.quran));
          commit("SET_KORAN", newExpense);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async updateArray( { commit }) {
      const requests = [];
      const data = JSON.parse((await get("quran-data")) || null);

      const commitData = (data) => {
        commit("SET_NEW", data);
        commit(
          "SET_ARRAY",
          data.reduce(
            (acc, el) => [
              ...acc,
              el.data.quran.find(
                (el2) => el2.translation === "2" && el2.stih === "0"
              ),
            ],
            []
          )
        );
      };

      if (data) {
        commitData(data);
      }

      // for (let i = 1; i < 115; i++) {
      //   let data3 = "surah=" + i;
      //   requests.push(
      //     axios.post(
      //       "https://api.sabil.webinfra.ru/sabil",
      //       `{"path": "https://api.sabeel.credo.ru:5543/api/quran", "data": ${JSON.stringify(
      //         data3
      //       )}, "headers": {"Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=", "Content-Type": "application/x-www-form-urlencoded"}, "cookies": {"PHPSESSID": "${
      //         state.getPin
      //       }"}}`,
      //       {
      //         headers: {
      //           "Content-Type": "application/x-www-form-urlencoded",
      //         },
      //       }
      //     )
      //   );
      // }

      Promise.all(requests).then((res) => {
        commitData(res);
        set("quran-data", JSON.stringify(res));
      });
    },
  },
  modules: {},
});
