<template>
  <header>
    <div style="display: flex; justify-content: space-between">
      <router-link to="/profilenav">
        <div class="user">
          <img :src="userIconPath" alt="" class="user-icon" />

          <p class="user-name">{{ userName }}</p>
        </div>
      </router-link>

      <div class="controls">
        <img
          @click="$emit('toggleMenu')"
          src="../assets/Gamburger.png"
          class="header-icon"
        />
        <router-link to="/supportnav">
          <img
            @click="goSupport()"
            src="../assets/Gear.svg"
            alt=""
            class="header-icon"
          />
        </router-link>
        <img
          @click="$emit('toggleNots')"
          src="../assets/Bell.svg"
          alt=""
          class="header-icon"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { getUserData } from "@/api/api";

export default {
  name: "HeaderComponent",
  props: ["showNots", "showMenu"],
  data: () => ({
    userName: localStorage.getItem("sabil_name") ?? "",
    userIconPath:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png",
  }),
  methods: {
    goSupport() {
      this.$emit("goSupport", false);
    },
  },
  async created() {
    let that = this;
    try {
      const { data } = await getUserData();
      if (data.V_NAME) {
        that.userName = data.V_NAME;
        localStorage.setItem("sabil_name", data.V_NAME);
      } else {
        const alreadySavedName = localStorage.getItem("sabil_name");
        if (alreadySavedName) {
          that.userName = alreadySavedName;
        } else {
          that.userName = 'Unauthorized'
        }
      }
    } catch (e) {
      that.userName = localStorage.getItem("sabil_name") ?? "Server error";
    }
  },
};
</script>

<style scoped>
header {
  background-color: #39b2ba;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  height: 50px;
  left: 0;
  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));
  top: 0;
}
header:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/Pattern.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  opacity: 0.15;
}
.controls {
  display: flex;
}
.user {
  display: flex;
  align-items: center;
}

.user-icon,
.header-icon {
  width: 25px;
  background-repeat: no-repeat;
  height: 25px;
  color: black;
}

.user-name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  line-height: 17px;
  color: white;
}

.header-icon {
  margin-right: 10px;
}

.header-icon {
  margin-right: 10px;
}
</style>
