import axios from "axios";

const instance = axios.create({
    baseURL: 'https://api.sabil.webinfra.ru/sabil',
})

export const getUserData = () => {
    const params = JSON.stringify({
        path: "https://api.sabeel.credo.ru:5543/api/client",
        headers: {
            "Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        cookies: {"PHPSESSID": JSON.parse(localStorage.getItem("sessid"))}
    })
    return instance.post('', params)
}

export const getAuatData = () => {
    const params = JSON.stringify({
        path: "https://api.sabeel.credo.ru:5543/api/daily_ayat",
        headers: {
            "Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        cookies: {"PHPSESSID": "null"}
    })
    return instance.post('', params)
}

export const getBalanceData = () => {
    const params = JSON.stringify({
        path: "https://api.sabeel.credo.ru:5543/api/balance",
        headers: {
            "Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        cookies: {"PHPSESSID": JSON.parse(localStorage.getItem("sessid"))}
    })
    return instance.post('', params)
}

export const getPagesData = (paramsReq, pin) => {
    const params = JSON.stringify({
        path: "https://api.sabeel.credo.ru:5543/api/pages",
        "data": paramsReq,
        headers: {
            "Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        "cookies": {"PHPSESSID": pin}
    })
    return instance.post('', params)
}

export const likePage = (d, action, pin) => {
    let data3 = `page_id=${d.id}&folder_id=0&fav=${action}`
    const params = JSON.stringify({
        path: "https://api.sabeel.credo.ru:5543/api/fav_page",
        "data": data3,
        headers: {
            "Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        "cookies": {"PHPSESSID": pin}
    })
    return instance.post('', params)
}
export const getFavPages = (pin) => {
    const params = JSON.stringify({
        path: "https://api.sabeel.credo.ru:5543/api/fav_page_list",
        "data": "folder_id=0",
        headers: {
            "Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        "cookies": {"PHPSESSID": pin}
    })
    return instance.post('', params)
}

export const getAllDataMainPage = (data, pin) => {
    return Promise.all([ getAuatData(),
        getBalanceData(), getPagesData(data, pin), getFavPages(pin)]).then((data) => data.map(({data}) => data))
}


export const getArticle = (id, pin) => {
    const params = JSON.stringify({
        path: "https://api.sabeel.credo.ru:5543/api/page",
        "data": id,
        headers: {
            "Authorization": "Basic bW9iaWxlYXBwOlZvd29vOHpvb0o=",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        "cookies": {"PHPSESSID": pin}
    })
    return instance.post('', params)
}